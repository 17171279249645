// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.footer_footer__R1A6a{
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem 0;
    background-color: #0D0D0D;
}

.footer_footerctt__VpzQn{
    position: relative;
    max-width: 1200px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    padding: 2%;
}

.footer_imglogo__oXACr{
    object-fit: contain;
}

.footer_footerctt__VpzQn{
    font-size: 14px;
}

@media(max-width:1000px){
    .footer_footerctt__VpzQn{
        padding: 5% 10%;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Footer/footer.module.css"],"names":[],"mappings":";AACA;IACI,kBAAkB;IAClB,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,eAAe;IACf,yBAAyB;AAC7B;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,uBAAuB;IACvB,WAAW;AACf;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI;QACI,eAAe;IACnB;AACJ","sourcesContent":["\n.footer{\n    position: relative;\n    width: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    padding: 2rem 0;\n    background-color: #0D0D0D;\n}\n\n.footerctt{\n    position: relative;\n    max-width: 1200px;\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    gap: 1rem;\n    justify-content: center;\n    padding: 2%;\n}\n\n.imglogo{\n    object-fit: contain;\n}\n\n.footerctt{\n    font-size: 14px;\n}\n\n@media(max-width:1000px){\n    .footerctt{\n        padding: 5% 10%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `footer_footer__R1A6a`,
	"footerctt": `footer_footerctt__VpzQn`,
	"imglogo": `footer_imglogo__oXACr`
};
export default ___CSS_LOADER_EXPORT___;
