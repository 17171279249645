import React from 'react'
import styles from './conversion.module.css'

const Conversion = () => {
  return (
    <div className={styles.cvs_bg}>
        <div className={styles.cvs_content}>
            <div>
              <picture>
                <source srcSet="/new/formb.png" media="(max-width:800px)" sizes="(max-width: 800px) 100vw, 50vw" />
                <img src="/new/banner2.png" alt="Imagem chat" className={styles.cvsimg} />
              </picture>
            </div>
            <div className={styles.cvs_focus}>
                <h2>Transforme Seu Atendimento com IA e Acionamento Inteligente</h2>
                <p className='textglobal'>Nossa IA de <b>linguagem natural</b> oferece mais que um simples serviço: ela automatiza um ciclo recorrente que <b>aciona seus clientes</b> em momentos estratégicos, mantendo-os sempre conectados e engajados.</p>
                
            </div>
        </div>
    </div>
  )
}

export default Conversion
