import React from 'react'
import styles from './first.module.css'

const FirstContent = ({ scrollToRef, contatoRef }) => {
  
  return (
    <div className={styles.first}>
      <div className={styles.cttfirst}>
        <div className={styles.textctt}>
          <p className={styles.text1}>Não perca tempo!</p>
          <p><b>Revolucione seu atendimento com nossa IA para WhatsApp</b>. Agendamentos, mensagens periódicas e atendimento inteligente. <span className='color1'><b>Transforme a experiência dos seus clientes agora!</b></span></p>
        </div>
        <div>
          <p className={styles.text2}>R$ 45,90/mês</p>
        </div>
        <div>
          <button className='button' onClick={() => scrollToRef(contatoRef)}>CONTRATAR AGORA</button>
        </div>
      </div>
    </div>
  )
}

export default FirstContent
