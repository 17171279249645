import React, {useState} from 'react'
import styles from './header.module.css'

const HeaderComp = ({ scrollToRef, contatoRef }) => {
    const [textInput, setTextInput] = useState("Olá Send")
    const data = [
        { src: "/new/atend.svg", alt: "Ícone pessoa atendendo", text: "Pós-Atendimento" },
        { src: "/new/ling.svg", alt: "Ícone pessoa atendendo", text: "Linguagem Natural" },
        { src: "/new/otimi.svg", alt: "Ícone pessoa atendendo", text: "Otimização de Atendimento" },
        { src: "/new/progra.svg", alt: "Ícone pessoa atendendo", text: "Acionamento programado" },
        { src: "/new/conta.svg", alt: "Ícone pessoa atendendo", text: "Contato Ágil" },
    ]

    

    return (
        <div className={styles.header_bg}>
            <div className={styles.header_content}>
                <div className={styles.hd_1}>
                    <h1>Quer revolucionar seu atendimento?</h1>
                    <p>Conheça a <b className='color2'>SEND</b>, sua assistente virtual focada em relacionamento com cliente!</p>
                    <div className={styles.chatcol}>
                        <p>Converse com ela agora clicando no botão abaixo!</p>
                        <div className={styles.hd_row3}>  
                            <div className={styles.btnchat}>
                                <input type='text' className='input2' value={textInput} onChange={(e) => setTextInput(e.target.value)}></input>
                            </div>
                            <button className={'button3'} onClick={() => scrollToRef(contatoRef)}>Send</button>
                        </div>
                    </div>
                </div>
                <div className={styles.header_secd}>
                    <div className={styles.header_skils}>
                        {data && data.map((item, index) => (
                            <div key={index} className={styles.hd_row2}>
                                <img src={item.src} alt={item.alt} style={{ width: '30px', height: '30px' }} />
                                <p>{item.text}</p>
                            </div>
                        ))}
                    </div>
                </div>
                    
            </div>
        </div>
    )
}
export default HeaderComp
