import React, { forwardRef } from 'react'
import styles from './contato.module.css'
import Form from '../Form'

const Contato = forwardRef((props, ref) => {
  return (
    <div ref={ref} className={styles.ctt}>
        <div className={styles.cttcontent}>
            {/* Conteúdo do componente Contato */}
            <div className={styles.col}>
              <h2>CONTATO</h2>
              <p>Informe seus dados abaixo para que a Send te acione via Whatsapp!</p>
            </div>
            <Form />
        </div>
    </div>
  )
})

export default Contato