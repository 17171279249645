import React from 'react';
import styles from './revolution.module.css';

const Revolution = ({ scrollToRef, contatoRef }) => {

  
  const content1 = {
    title: "Para seu cliente",
    content: [
      {
        img: "/new/icons/wpp.png",
        alt: "Whatsapp",
        text: "Contato rápido"
      },
      {
        img: "/new/icons/cerebro.png",
        alt: "Cérebro",
        text: "IA Linguagem Natural"
      },
      {
        img: "/new/icons/calendario.png",
        alt: "Calendário",
        text: "Agendamento Automático"
      },
      {
        img: "/new/icons/24h.png",
        alt: "Whatsapp 24h",
        text: "Atendimento 24horas"
      }
    ]
  }
  
  const content2 = {
    title: "Conexão Pós-Atendimento",
    content: [
      {
        img: "/new/icons/chat.png",
        alt: "Chat",
        text: "Acionamentos periódicos"
      },
      {
        img: "/new/icons/alvo.png",
        alt: "Alvo",
        text: "Promoções"
      },
      {
        img: "/new/icons/satisfacao.png",
        alt: "Velocimetro com sorriso e cara triste",
        text: "Pesquisa de Satisfação"
      }
    ]
  }

  return (
    <div className={styles.rev_bg}>
        <div className={styles.rev_content}>
            <div className={styles.rev_text}>
                <h2>Experiência Completa: Atendimento e Pós-Atendimento Eficiente</h2>
                <p className='textglobal'>Oferecemos um <b>atendimento 24 horas via WhatsApp</b> para garantir que seus clientes sejam sempre atendidos. Após o atendimento, continuamos cuidando de cada detalhe, assegurando que todas as necessidades sejam resolvidas com <b>eficiência e rapidez</b>.</p>
            </div>
            <div className={styles.rev_ct1}>
              <h3>{content1.title}</h3>
              <div className={styles.itemrev}>
                {content1.content.map((item, index) => (
                  <div key={index} className={styles.dvrow}>
                    <img src={item.img} alt={item.alt} />
                    <p className='textglobal'><b>{item.text}</b></p>
                  </div>
                ))} 
              </div>
            </div>

            {/* Título e conteúdo de content2 */}
            <div className={styles.rev_ct2}>
              <h3>{content2.title}</h3>
              <div className={styles.itemrev}>
                {content2.content.map((item, index) => (
                  <div key={index} className={styles.dvrownodetail}>
                    <img src={item.img} alt={item.alt} />
                    <p className='textglobal'><b>{item.text}</b></p>
                  </div>
                ))}
              </div>
              <button className='button' onClick={() => scrollToRef(contatoRef)}>INICIE HOJE</button>
            </div>
        </div>
    </div>
  )
}

export default Revolution
