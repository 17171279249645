// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.engagement_engg_bg__rMEGf{
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
}

.engagement_engg_content__N\\+md7{
    position: relative;
    max-width: 1200px;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 2rem;
    align-items: center;
    justify-content: center;
    padding: 3% 0;
}

.engagement_engg_text__\\+S4c7{
    position: relative;
    display: flex;
    flex-direction: column;
    gap:1rem;
}

.engagement_engg_text__\\+S4c7 b{
    color: #FF5C33;
}
     
.engagement_engimg__ttgSY{
    transition: transform 0.3s ease; /* Adiciona uma transição suave ao efeito */
}

.engagement_engimg__ttgSY:hover{
    transform: scale(0.95); /* Aumenta o tamanho do conteúdo em 10% */
}

@media(max-width: 1000px){
    .engagement_engg_bg__rMEGf{
        padding: 5% 10%;
    }
    .engagement_engg_content__N\\+md7{
        flex-direction: column;
        gap:1rem;
        text-align: left;
        align-items: center;
    }
            
    .engagement_engimg__ttgSY{
        width: 300px;
        height: auto;
    }

    .engagement_engg_text__\\+S4c7{
        align-items: center;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Engagement/engagement.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,UAAU;AACd;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,QAAQ;AACZ;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,+BAA+B,EAAE,2CAA2C;AAChF;;AAEA;IACI,sBAAsB,EAAE,yCAAyC;AACrE;;AAEA;IACI;QACI,eAAe;IACnB;IACA;QACI,sBAAsB;QACtB,QAAQ;QACR,gBAAgB;QAChB,mBAAmB;IACvB;;IAEA;QACI,YAAY;QACZ,YAAY;IAChB;;IAEA;QACI,mBAAmB;IACvB;AACJ","sourcesContent":[".engg_bg{\n    position: relative;\n    width: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    padding: 0;\n}\n\n.engg_content{\n    position: relative;\n    max-width: 1200px;\n    width: 100%;\n    display: flex;\n    flex-direction: row;\n    gap: 2rem;\n    align-items: center;\n    justify-content: center;\n    padding: 3% 0;\n}\n\n.engg_text{\n    position: relative;\n    display: flex;\n    flex-direction: column;\n    gap:1rem;\n}\n\n.engg_text b{\n    color: #FF5C33;\n}\n     \n.engimg{\n    transition: transform 0.3s ease; /* Adiciona uma transição suave ao efeito */\n}\n\n.engimg:hover{\n    transform: scale(0.95); /* Aumenta o tamanho do conteúdo em 10% */\n}\n\n@media(max-width: 1000px){\n    .engg_bg{\n        padding: 5% 10%;\n    }\n    .engg_content{\n        flex-direction: column;\n        gap:1rem;\n        text-align: left;\n        align-items: center;\n    }\n            \n    .engimg{\n        width: 300px;\n        height: auto;\n    }\n\n    .engg_text{\n        align-items: center;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"engg_bg": `engagement_engg_bg__rMEGf`,
	"engg_content": `engagement_engg_content__N+md7`,
	"engg_text": `engagement_engg_text__+S4c7`,
	"engimg": `engagement_engimg__ttgSY`
};
export default ___CSS_LOADER_EXPORT___;
