// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contato_ctt__H5rnX{
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem 0;
}
.contato_cttcontent__rzXF2 {
    position: relative;
    max-width: 1200px;
    width: 40%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    justify-content: flex-end;
    border-radius: 30px;
    padding: 2.5%;
    
}


.contato_cttcontent__rzXF2 h2{
    font-size: 100px !important;
    color: #8933FF;
}
.contato_col__a1WbC{
    display: flex;
    flex-direction: column;
    color:#8933FF;
    text-align: center;
}



@media(max-width: 1000px){
    .contato_ctt__H5rnX{
        padding:5% 10%;
    }
    .contato_cttcontent__rzXF2{
        gap:1rem;
        width: 100%;
    }
    .contato_cttcontent__rzXF2 h2{
        font-size: 48px !important;
    }
    .contato_col__a1WbC{
        align-items: center;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Contato/contato.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,eAAe;AACnB;AACA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,UAAU;IACV,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,mBAAmB;IACnB,yBAAyB;IACzB,mBAAmB;IACnB,aAAa;;AAEjB;;;AAGA;IACI,2BAA2B;IAC3B,cAAc;AAClB;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,aAAa;IACb,kBAAkB;AACtB;;;;AAIA;IACI;QACI,cAAc;IAClB;IACA;QACI,QAAQ;QACR,WAAW;IACf;IACA;QACI,0BAA0B;IAC9B;IACA;QACI,mBAAmB;IACvB;AACJ","sourcesContent":[".ctt{\n    position: relative;\n    width: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    padding: 2rem 0;\n}\n.cttcontent {\n    position: relative;\n    max-width: 1200px;\n    width: 40%;\n    display: flex;\n    flex-direction: column;\n    gap: 2rem;\n    align-items: center;\n    justify-content: flex-end;\n    border-radius: 30px;\n    padding: 2.5%;\n    \n}\n\n\n.cttcontent h2{\n    font-size: 100px !important;\n    color: #8933FF;\n}\n.col{\n    display: flex;\n    flex-direction: column;\n    color:#8933FF;\n    text-align: center;\n}\n\n\n\n@media(max-width: 1000px){\n    .ctt{\n        padding:5% 10%;\n    }\n    .cttcontent{\n        gap:1rem;\n        width: 100%;\n    }\n    .cttcontent h2{\n        font-size: 48px !important;\n    }\n    .col{\n        align-items: center;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ctt": `contato_ctt__H5rnX`,
	"cttcontent": `contato_cttcontent__rzXF2`,
	"col": `contato_col__a1WbC`
};
export default ___CSS_LOADER_EXPORT___;
