// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.divErrorRow{
    display: flex;
    flex-direction: row;
    gap:16px;   
    max-width: 40%;
    height: auto;
    align-items: center;
    padding-left: 10px;
}

.divErrorColumn{
    display: flex;
    flex-direction: column;
}


.text16{
    font-size: 16px;
}

.text20{
    font-size: 20px;
    color: #03A8CC;
}

@media (max-width: 768px) {
    .divErrorRow{
        max-width: 100%;
    }


}`, "",{"version":3,"sources":["webpack://./src/components/DivError/DivError.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,QAAQ;IACR,cAAc;IACd,YAAY;IACZ,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;;;AAGA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,cAAc;AAClB;;AAEA;IACI;QACI,eAAe;IACnB;;;AAGJ","sourcesContent":[".divErrorRow{\n    display: flex;\n    flex-direction: row;\n    gap:16px;   \n    max-width: 40%;\n    height: auto;\n    align-items: center;\n    padding-left: 10px;\n}\n\n.divErrorColumn{\n    display: flex;\n    flex-direction: column;\n}\n\n\n.text16{\n    font-size: 16px;\n}\n\n.text20{\n    font-size: 20px;\n    color: #03A8CC;\n}\n\n@media (max-width: 768px) {\n    .divErrorRow{\n        max-width: 100%;\n    }\n\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
