import React from 'react';
import styles from './engagement.module.css';

const Engagement = ({ scrollToRef, contatoRef }) => {
  return (
    <div className={styles.engg_bg}>
        <div className={styles.engg_content}>
            <img src="/new/engag.png" alt="Imagem time" className={styles.engimg} />
            <div className={styles.engg_text}>
              <h2>Engaje e Amplie sua Base de Clientes com Acionamento Recorrente</h2>
              <p className='textglobal'>Mantenha seus clientes sempre engajados e valorizados com nosso sistema de <b>acionamento recorrente</b>. Nosso serviço não apenas facilita o agendamento inteligente, mas também realiza <b>contatos periódicos</b> com seus clientes desde o último atendimento. Isso garante que sua <b>base de clientes</b> permaneça <b>ativa e conectada</b>, enquanto você aumenta o valor de cada relacionamento. Com nosso serviço, você não só fideliza clientes existentes, mas também atrai novos, <b>ampliando sua base</b> de forma consistente e eficaz.</p>
              <div><button className='button' onClick={() => scrollToRef(contatoRef)}>AMPLIE SUA BASE</button></div>
            </div>
        </div>
    </div>
  );
};

export default Engagement;
