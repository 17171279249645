import React from 'react';
import styles from './skills.module.css';

const Skills = ({ scrollToRef, contatoRef }) => {
    
    const data = [
        {
        text: "Agendamento Prático",
        src: "/new/icons/calen2.png",
        alt: "Ícone de calendário"
        },
        {
        text: "Acionamentos Recorrentes",
        src: "/new/icons/foguete.png",
        alt: "Ícone de meta"
        },
        {
        text: "Linguagem Natural",
        src: "/new/icons/chat2.png",
        alt: "Ícone de conversa"
        }
    ];
    return (
        <div className={styles.skills_bg}>
            <div className={styles.skills_content}>
                
                <div className={styles.cttr}>
                    <div className={styles.textcont}>
                        <div className='div100' ><h2>Agendamento Inteligente com IA em Linguagem Natural</h2></div>
                        <p className='textglobal'>Com nossa inteligência artificial de linguagem natural, você vai além de um simples serviço. Implementamos um ciclo recorrente que <b>aciona seus clientes de forma automática e estratégica</b> em tempos pré-definidos. Nosso sistema oferece um <b>atendimento ativo e receptivo</b>, mantendo seus clientes sempre conectados e envolvidos ao longo do tempo.</p>
                        <div className={styles.btweb}><button className='button' onClick={() => scrollToRef(contatoRef)}>QUERO CONTRATAR</button></div>
                    </div>
                    <div className={styles.skills_imgs}>
                        {data.map((item, index) => (
                            <div key={index} className={styles.client_itemskil}>
                                <img src={item.src} alt={item.alt} className={styles.imgai} />
                                <p className={styles.itemtext}>{item.text}</p>
                            </div>
                        ))}
                        <div className={styles.btmb} ><button className='button' onClick={() => scrollToRef(contatoRef)}>QUERO CONTRATAR</button></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Skills;
