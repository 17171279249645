import React, { useState } from 'react'
import './menumobile.css'
import { Link } from 'react-router-dom'
import ButtonPadrao from '../ButtonPadrao'

function EnzoMenuMobile() {
    const [enzoMenuOpened, setEnzoMenuOpened] = useState(false)

    const menuOpenClose = async () => {
        setEnzoMenuOpened(!enzoMenuOpened)
    }
  return (
    <div className='div_menumobile_general'>
        <div onClick={menuOpenClose}><b>☰   MENU</b></div>
        {enzoMenuOpened &&
            <div className='div_menumobile_general_buttons'>
                <Link to="/home"><div><b>🏠 Home</b></div></Link>
                <Link to="/companie"><div><b>🛠️ Companie</b></div></Link>
                <Link to="/talk"><div><b>💬 Fale com a Send</b></div></Link>
                <Link to="/showchats"><div><b>🗨️ Chats</b></div></Link>
                <Link to="/logout"><div><b>LOGOUT</b></div></Link>
            </div>
        }
    </div>
  )
}

export default EnzoMenuMobile
