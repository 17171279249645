// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ia_ia_bg__RZOOe{
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem 0;
}

.ia_ia_content__zJRxQ{
    position: relative;
    max-width: 1200px;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 2rem;
    align-items: center;
}

.ia_ia_content__zJRxQ h2{
    color: #FF5C33;
    text-align: right;
}

.ia_ia_ct1__c\\+Ysj{
    width: 50%;
}

.ia_ia_ct2__Yt8jO{
    width: 50%;
}

@media(max-width: 1000px){
    .ia_ia_bg__RZOOe{
        padding: 5% 10%;
    }
    .ia_ia_content__zJRxQ{
        flex-direction: column;
        gap:1rem;
        text-align: left;
    }

    .ia_ia_content__zJRxQ h2{
        text-align: left;
    }
    .ia_ia_ct1__c\\+Ysj{
        width: auto;
    }
    
    .ia_ia_ct2__Yt8jO{
        width: auto;
    }

}`, "",{"version":3,"sources":["webpack://./src/components/IA/ia.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,iBAAiB;AACrB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;AACd;;AAEA;IACI;QACI,eAAe;IACnB;IACA;QACI,sBAAsB;QACtB,QAAQ;QACR,gBAAgB;IACpB;;IAEA;QACI,gBAAgB;IACpB;IACA;QACI,WAAW;IACf;;IAEA;QACI,WAAW;IACf;;AAEJ","sourcesContent":[".ia_bg{\n    position: relative;\n    width: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    padding: 2rem 0;\n}\n\n.ia_content{\n    position: relative;\n    max-width: 1200px;\n    width: 100%;\n    display: flex;\n    flex-direction: row;\n    gap: 2rem;\n    align-items: center;\n}\n\n.ia_content h2{\n    color: #FF5C33;\n    text-align: right;\n}\n\n.ia_ct1{\n    width: 50%;\n}\n\n.ia_ct2{\n    width: 50%;\n}\n\n@media(max-width: 1000px){\n    .ia_bg{\n        padding: 5% 10%;\n    }\n    .ia_content{\n        flex-direction: column;\n        gap:1rem;\n        text-align: left;\n    }\n\n    .ia_content h2{\n        text-align: left;\n    }\n    .ia_ct1{\n        width: auto;\n    }\n    \n    .ia_ct2{\n        width: auto;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ia_bg": `ia_ia_bg__RZOOe`,
	"ia_content": `ia_ia_content__zJRxQ`,
	"ia_ct1": `ia_ia_ct1__c+Ysj`,
	"ia_ct2": `ia_ia_ct2__Yt8jO`
};
export default ___CSS_LOADER_EXPORT___;
