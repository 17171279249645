import React from 'react';
import styles from './revolution.module.css';

const Revolution = () => {


  return (
    <div className={styles.rev_bg}>
        <div className={styles.rev_content}>
            <div className={styles.rev_text}>
                <h2>Experiência Completa: Atendimento e Pós-Atendimento Eficiente</h2>
                <p className='textglobal'>Oferecemos um <b>atendimento 24 horas via WhatsApp</b> para garantir que seus clientes sejam sempre atendidos. Após o atendimento, continuamos cuidando de cada detalhe, assegurando que todas as necessidades sejam resolvidas com <b>eficiência e rapidez</b>.</p>
            </div>
        </div>
    </div>
  )
}

export default Revolution
