import './EnzoCompanie.css'
import React from 'react'
import { useState, useEffect, useContext, useRef } from 'react'
import logoPreta from '../img/send.png'
import errorImgRoxo from '../img/send.png'
import ButtonPadrao from '../../components/ButtonPadrao'
import Input from '../../components/Input'
import Logo from '../../components/Logo'
import Img from '../../components/Img'
import axios from 'axios'
import EnzoMenu from '../../components/EnzoMenu'
import EnzoMenuMobile from '../../components/EnzoMenuMobile'
import { AuthContext } from '../../context/AuthContext'
import { Navigate } from 'react-router-dom'
import DivError from '../../components/DivError'
import ItemReturnEdit from '../../components/ItemReturnEdit'
import iconUrl from '../../icons/url.png'
import iconEditGray from '../../icons/editG.png'
import iconToken from '../../icons/psw.png'
import iconCalendar from '../../icons/calendar.png'
import iconTreinamento from '../../icons/treinamento.png'
import iconList from '../../icons/list.png'
import iconId from '../../icons/id_real.png'
import iconRegister from '../../icons/iconregister.png'
import iconTech from '../../icons/tech.png'
import loadingRosa from '../img/send.png'
import LoadingImageRotate from '../../components/LoadingImageRotate'

const EnzoCompanie = () => {
    const [insertText, setInsertText] = useState(null)
    const [fileContent, setFileContent] = useState(null)
    const [divInsertFile, setDivInsertFile] = useState(false)
    const [divInsertText, setDivInsertText] = useState(false)
    const [companieRegister, setCompanieRegister] = useState(null) 
    const [wordCount, setWordCount] = useState(0)
    const [wordCountText, setWordCountText] = useState(0)
    const [loadingRegister, setLoadingRegister] = useState(false)
    const [callbackRoute, setCallbackRoute] = useState(null)
    const [arrayToSend, setArrayToSend] = useState([])
    const [putArrayToSend, setPutArrayToSend] = useState([])
    const [inputValue, setInputValue] = useState(null)
    const [inputPutTechChoice, setInputPutTechChoice] = useState(null)
    const [wppTokenAcess, setWppTokenAcess] = useState(null)
    const [wppNumberId, setWppNumberId] = useState(null)
    const [callbackRouteToken, setCallbackRouteToken] = useState(null)
    const [techChoice, setTechChoice] = useState(null) 
    const [divNewCompanie, setDivNewCompanie] = useState(false)
    const [loadingGetCompanies, setLoadingGetCompanies] = useState(false)
    const [divErrorGetCompanie, setDivErrorGetCompanie] = useState(false)
    const [errorCompanieCode, setErrorCompanieCode] = useState(null) 
    const [errorCompanieCodeText, setErrorCompanieCodeText] = useState(null)
    const [errorCompanieText, setErrorCompanieText] = useState(null)
    const [divErrorNewCompanie, setDivErrorNewCompanie] = useState(false)
    const [errorNewCompanieCode, setErrorNewCompanieCode] = useState(null) 
    const [errorNewCompanieCodeText, setErrorNewCompanieCodeText] = useState(null)
    const [errorNewCompanieText, setErrorNewCompanieText] = useState(null)
    const [selectedGetCompanie, setSelectedGetCompanie] = useState(null)
    const [returnGetCompanie, setReturnGetCompanie] = useState(null)

    const [divModelTrainExpand, setDivModelTrainExpand] = useState(false)
    const [divPutName, setDivPutName] = useState(false)
    const [putCompanieValueName, setPutCompanieValueName] = useState(null)
    const [divPutModelTrain, setDivPutModelTrain] = useState(false)
    const [divPutCallbackUrl, setDivPutCallbackUrl] = useState(false)
    const [divPutChatEngine, setDivPutChatEngine] = useState(false)
    const [putCompanieValueCallbackUrl, setPutCompanieValueCallbackUrl] = useState(null)
    const [divPutDesiredData, setDivPutDesiredData] = useState(false)
    const [putDesiredData, setPutDesiredData] = useState(null)
    const [divPutWppTokenAccess, setDivPutWppTokenAccess] = useState(false)
    const [putCompanieValueWppTokenAccess, setPutCompanieValueWppTokenAccess] = useState(null)
    const [divPutTokenAccessWppId, setDivPutTokenAccessWppId] = useState(false)
    const [putCompanieValueWppId, setPutCompanieValueWppId] = useState(null)
    const [divPutCallbackAuth, setDivPutCallbackAuth] = useState(false)
    const [putCompanieValueCallbackAuth, setPutCompanieValueCallbackAuth] = useState(null)
    const [divPutCompanieValueModelTrainText, setDivPutCompanieValueModelTrainText] = useState(false)
	const [putCompanieValueModelTrainText, setPutCompanieValueModelTrainText] = useState(null)
    const [divPutCompanieValueModelTrainFile, setDivPutCompanieValueModelTrainFile] = useState(false)
	const [putCompanieValueModelTrainFile, setPutCompanieValueModelTrainFile] = useState(null)
	const [selectedGetCompanieAuth, setSelectedGetCompanieAuth] = useState(null)
    const [showNotificationCompanie, setShowNotificationCompanie] = useState(false)
    const [showAlertCompanie, setShowAlertCompanie] = useState(false)

	const [notificationContent, setNotificationContent] = useState(null)
	const [alertContent, setAlertContent] = useState(null)
    

    const [putCompanieWordCount, setPutCompanieWordCount] = useState(0)
    const [putCompanieFileWordCount, setPutCompanieFileWordCount] = useState(0)

    const inputRefPost = useRef(null)
    const inputRefPut = useRef(null)
    
    
    const { accessToken } = useContext(AuthContext)

	
    useEffect(() => {
        if(!accessToken){
            <Navigate to="/login" />
        }
    }, [accessToken])

    useEffect(() => {

        if (showNotificationCompanie) {
        const timeoutId = setTimeout(() => {
            setShowNotificationCompanie(false)
            setNotificationContent('')
        }, 2000)

        return () => clearTimeout(timeoutId)
        }
    }, [showNotificationCompanie])


    useEffect(() => {

        if (showAlertCompanie) {
        const timeoutId = setTimeout(() => {
            setShowAlertCompanie(false)
            setAlertContent('')
        }, 2000)

        return () => clearTimeout(timeoutId)
        }
    }, [showAlertCompanie])






        // Função para adicionar um item ao array e tratar antes de enviar
    const addItem = () => {
        const insertValue = inputValue.trim().toLowerCase() // Remove espaços e converte para minúsculas

        if (/^[a-z_]+$/.test(insertValue)) { // Permite letras minúsculas e "_"
        setArrayToSend([...arrayToSend, insertValue])
        setInputValue(null)
        } else {
            //alert('Digite apenas letras minúsculas e "_" (sem espaços ou caracteres especiais).')
            setAlertContent('🔔 Permitido: letras minúsculas e _ ao invés de espaço.')
            setShowAlertCompanie(true)
        }
    }

        // Função para adicionar um item ao array e tratar antes de enviar
    const putAddItem = () => {
        const insertValuePut = putDesiredData.trim().toLowerCase() // Remove espaços e converte para minúsculas
        
        if (/^[a-z_]+$/.test(insertValuePut)) { // Permite letras minúsculas e "_"
        setPutArrayToSend([...putArrayToSend, insertValuePut])
        setPutDesiredData(null)
        } else {
            setAlertContent('🔔 Permitido: letras minúsculas e _ ao invés de espaço.')
            setShowAlertCompanie(true)
            //alert('Digite apenas letras minúsculas e "_" (sem espaços ou caracteres especiais).')
        }
    }

    const handlePutFileChange = async (e) => {
        const file = e.target.files[0]
        if (file) {
        const reader = new FileReader()
        reader.onload = (event) => {
            const content = event.target.result
            setPutCompanieValueModelTrainFile(content)
        }
        reader.readAsText(file)
        }
    }
    

    const handleFileChange = async (e) => {
        const file = e.target.files[0]
        if (file) {
        const reader = new FileReader()
        reader.onload = (event) => {
            const content = event.target.result
            setFileContent(content)
        }
        reader.readAsText(file)
        }
    }
    //UseEffect para fazer contagem das palavras tanto do texto quanto do arquivo .txt
    useEffect(() => {
        const countWords = (text) => {
            const words = text.split(/\s+/)
            return words.length
        }
        if (fileContent) {
            const wordsCount = countWords(fileContent)
            setWordCount(wordsCount)
            if (wordsCount >= 14000) {
                setAlertContent('🔔 Treinemnto excedeu o número máximo de palavras! :(')
                setShowAlertCompanie(true)
                //alert('O treinamento inserido excedeu o número máximo de palavras! :(')
            }
          }
        if (insertText) {
            const wordsCountText = countWords(insertText)
            setWordCountText(wordsCountText)
            if (wordsCountText >= 14000) {
                setAlertContent('🔔 Treinemnto excedeu o número máximo de palavras! :(')
                setShowAlertCompanie(true)
                //alert('🔔 O treinamento escrito excedeu o número máximo de palavras! :(')
            }
        }
        
        if (putCompanieValueModelTrainFile) {
            const wordsCount = countWords(putCompanieValueModelTrainFile)
            setPutCompanieFileWordCount(wordsCount)
            if (wordsCount >= 14000) {
                setAlertContent('🔔 O treinamento excedeu o número máximo de palavras! :(')
                setShowAlertCompanie(true)
                //alert('🔔 O treinamento inserido excedeu o número máximo de palavras! :(')
            }
        }

        if (putCompanieValueModelTrainText) {
            const wordsCount = countWords(putCompanieValueModelTrainText)
            setPutCompanieWordCount(wordsCount)
            if (wordsCount >= 14000) {
                setAlertContent('🔔 O treinamento excedeu o número máximo de palavras! :(')
                setShowAlertCompanie(true)
                //alert('🔔 O treinamento escrito excedeu o número máximo de palavras! :(')
            }
        }
       
    }, [fileContent, insertText, putCompanieValueModelTrainFile, putCompanieValueModelTrainText])
    
    const functionClickInput = () => {
        setTimeout(() => {
            inputRefPost.current.click()
        }, 0)
    }
    const functionClickInputPut = () => {
        setTimeout(() => {
            inputRefPut.current.click()
        }, 0)
    }

    const buttonInsertFile =  () => {
        setDivInsertFile(true)
        setDivInsertText(false)
        setInsertText(null)
        setWordCountText(0)
        functionClickInput()
    }
    const buttonInsertText =  () => {
        setDivInsertFile(false)
        setDivInsertText(true)
        setWordCount(0)
        setFileContent(null)
    }

    const putButtonInsertFile =  () => {
		setDivPutCompanieValueModelTrainText(false)
		setPutCompanieValueModelTrainText(null)
		setDivPutCompanieValueModelTrainFile(true)
        functionClickInputPut()
		
    }
    const putButtonInsertText =  () => {
		setDivPutCompanieValueModelTrainFile(false)
		setPutCompanieValueModelTrainFile(null)
		setDivPutCompanieValueModelTrainText(true)
    }
    
    const resetEndRegisterCompanie = () => { 
        setCompanieRegister(null)
        setCallbackRoute(null)
        setCallbackRouteToken(null)
        setWordCountText(0)
        setWordCount(0)
        setInsertText(null)
        setDivInsertText(false)
        setDivInsertFile(false)
        setInputValue(null)
        setArrayToSend([])
        setWppTokenAcess(null)
        setWppNumberId(null)
        setDivNewCompanie(!divNewCompanie)
        setTechChoice(null)
    }

    
    const buttonGetCompanies = async () => {
        // Aqui limpar variáveis de error:
        setDivErrorGetCompanie(false)
        setErrorCompanieText(null)
        setErrorCompanieCodeText(null)
        setErrorCompanieCode(null)


        setLoadingGetCompanies(true)
        try {
            const response = await axios.get(`${process.env.REACT_APP_REQ}companie`,
                {
                    headers: {
                    Authorization: `Bearer ${accessToken}`
                    }
                })
            if (response.status === 200) {
                setReturnGetCompanie(response.data)
            }
        }catch (error) {
            console.log(error)
            console.log(error.response.data.msg)
            setDivErrorGetCompanie(true)
            setErrorCompanieText(error.response.data.msg)
            setErrorCompanieCodeText(error.response.statusText)
            setErrorCompanieCode(error.response.status)
        }finally {
            setLoadingGetCompanies(false)
        }
    }
    
    
    const buttonNewCompanie = async () => {
        setDivErrorNewCompanie(false)
        setErrorNewCompanieText(null)
        setErrorNewCompanieCodeText(null)
        setErrorNewCompanieCode(null)

        if(!companieRegister){
            setAlertContent('🔔 Verificar: Nome companie!')
            setShowAlertCompanie(true)
            return
        }
        if(!fileContent && !insertText){
            setAlertContent('🔔 Verificar: Treinamento')
            setShowAlertCompanie(true)
            return
        }
        if(wordCount >= 14000){
            setAlertContent('🔔 Treinamento excedeu quantidade de palavras(14000).')
            setShowAlertCompanie(true)
            return
        }
        if(wordCountText >= 14000){
            setAlertContent('🔔 Treinamento excedeu quantidade de palavras(14000).')
            setShowAlertCompanie(true)
            return
        }
        if(!techChoice){
            setAlertContent('🔔 Verificar: Tecnologia do chat!')
            setShowAlertCompanie(true)
            return
        }
        
        let model_train

        if (insertText) {
            model_train = insertText
        } else {
            model_train = fileContent
        }


        let arrayPostCompanie = {
            name: companieRegister,
            model_train: model_train
        }

        if (callbackRoute) {
            arrayPostCompanie.callback_url = callbackRoute
        }
    
        if (arrayToSend) {
            arrayPostCompanie.desired_data = arrayToSend
        }

        if (wppTokenAcess) {
            arrayPostCompanie.whatsapp_token_access = wppTokenAcess
        }

        if (wppNumberId) {
            arrayPostCompanie.whatsapp_number_id = wppNumberId
        }
        
        if (callbackRouteToken) {
            arrayPostCompanie.callback_auth = callbackRouteToken
        }

        if (techChoice) {
            arrayPostCompanie.chat_engine = techChoice
        }

        setLoadingRegister(true)

        try {
            
            const response = await axios.post(`${process.env.REACT_APP_REQ}companie`, arrayPostCompanie,
            {
                headers: {
                Authorization: `Bearer ${accessToken}`
                }
            })
            if (response.status === 200) {
                setNotificationContent('🔔 Companie cadastrada com sucesso!')
                await setShowNotificationCompanie(true)
                await resetEndRegisterCompanie()
            }
        }catch (error) {
            await setErrorNewCompanieText(error.response.data.msg)
            await setErrorNewCompanieCodeText(error.response.statusText)
            await setErrorNewCompanieCode(error.response.status)
            await setDivErrorNewCompanie(true)
        }finally {
            setLoadingRegister(false)
        }

    }
    const handleItemClickGetCompanie = (itemId, itemAuthToken) => {
        setSelectedGetCompanie(itemId)
        setSelectedGetCompanieAuth(itemAuthToken)
    }

    const buttonShowNewCompanie = () => { 
        setDivNewCompanie(!divNewCompanie)
    }

    function formatDate(dataString) {
        const data = new Date(dataString)
        const dia = data.getDate().toString().padStart(2, '0')
        const mes = (data.getMonth() + 1).toString().padStart(2, '0')
        const ano = data.getFullYear()
    
        return `${dia}/${mes}/${ano}`
    }

    const handleSelectPutChange = (event) => {
        setInputPutTechChoice(event.target.value)
    }
    const handleSelectChange = (event) => {
        setTechChoice(event.target.value)
    }

    const handlePutChatEngine = () => {
        setDivPutChatEngine(!divPutChatEngine)
    }

    const handlePutCompanieName = async () => {
        setDivPutName(!divPutName)
    }
    const handlePutCompanieWppToken = async () => {
        setDivPutWppTokenAccess(!divPutWppTokenAccess)
    }
    const handlePutCompanieWppId = async () => {
        setDivPutTokenAccessWppId(!divPutTokenAccessWppId)
    }
    const handlePutCompanieCallbackUrl = async () => {
        setDivPutCallbackUrl(!divPutCallbackUrl)
    }
    const handlePutCompanieCallbackAuth = async () => {
        setDivPutCallbackAuth(!divPutCallbackAuth)
    }
    const handlePutCompanieModelTrain = async () => {
        setDivPutModelTrain(!divPutModelTrain)
    }
    const handlePutCompanieDesiredData = async () => {
        setDivPutDesiredData(!divPutDesiredData)
    }
    const functionExpandModelTrainCompanie = async () => {
        setDivModelTrainExpand(!divModelTrainExpand)
    }

    const functionPutCompanieName = async() => {
        if(!putCompanieValueName){
            setAlertContent('🔔 Verificar: Novo titulo companie!')
            setShowAlertCompanie(true)
            //alert('🔔 Você não informou um novo título para companie, favor verificar!')
            return
        }
        const idAsInteger = parseInt(selectedGetCompanie, 10)
        const data = {
            id : idAsInteger,
            name : putCompanieValueName
        }
            try {
                const response = await axios.put(`${process.env.REACT_APP_REQ}companie`, data,{
                    headers: {
                    Authorization: `Bearer ${accessToken}`
                    }
                })
                if (response.status === 200) {
                buttonGetCompanies()
                setPutCompanieValueName(null)
                setDivPutName(false)
            }
        }catch (error) {
            console.log(error)
            console.log(error.response.data.msg)
        }finally {
            //console.log('DEV - FIM PUT COMPANIE NAME')
        }
    }

    const functionPutCompanieModelTrain = async() => {
        if(!putCompanieValueModelTrainText && !putCompanieValueModelTrainFile){
            setAlertContent('🔔 Verificar: Treinamento!')
            setShowAlertCompanie(true)
            //alert('🔔 Favor inserir um arquivo .xtx de treinamento ou inserir o treinamento completo no input de texto!')
            return
        }
        let modelTrain

        if (putCompanieValueModelTrainText) {
            modelTrain = putCompanieValueModelTrainText
        } else {
            modelTrain = putCompanieValueModelTrainFile
        }
        const idAsInteger = parseInt(selectedGetCompanie, 10)
        const data = {
            id : idAsInteger,
            model_train : modelTrain
        }
            try {
                const response = await axios.put(`${process.env.REACT_APP_REQ}companie`, data,{
                    headers: {
                    Authorization: `Bearer ${accessToken}`
                    }
                })
                if (response.status === 200) {
                buttonGetCompanies()
                setPutCompanieValueModelTrainText(null)
                setPutCompanieValueModelTrainFile(null)
                setDivPutModelTrain(false)
            }
        }catch (error) {
            console.log(error)
            console.log(error.response.data.msg)
        }finally {
            console.log('DEV - FIM PUT COMPANIE MODELTRAIN')
        }
    }
    
    const functionPutCompanieCallbackUrl = async() => {
        
        if(!putCompanieValueCallbackUrl){
            setAlertContent('🔔 Verificar nova rota de callback!')
            setShowAlertCompanie(true)
            //alert('🔔 Você não informou uma nova rota de callback, favor verificar!')
            return
        }
        const idAsInteger = parseInt(selectedGetCompanie, 10)
        const data = {
            id : idAsInteger,
            callback_url : putCompanieValueCallbackUrl
        }
            try {
                const response = await axios.put(`${process.env.REACT_APP_REQ}companie`, data,{
                    headers: {
                    Authorization: `Bearer ${accessToken}`
                    }
                })
                if (response.status === 200) {
                buttonGetCompanies()
                setPutCompanieValueCallbackUrl(null)
                setDivPutCallbackUrl(false)
            }
        }catch (error) {
            console.log(error)
            console.log(error.response.data.msg)
        }finally {
            //console.log('DEV - FIM PUT COMPANIE CALLBACK URL')
        }
    }
    
    const functionPutCompanieCallbackAuth = async() => {

        if(!putCompanieValueCallbackAuth){
            setAlertContent('🔔 Verificar: Novo token!')
            setShowAlertCompanie(true)
            //alert('🔔 Você não informou um novo token, favor verificar!')
            return
        }
        const idAsInteger = parseInt(selectedGetCompanie, 10)
        const data = {
            id : idAsInteger,
            callback_auth : putCompanieValueCallbackAuth
        }
            try {
                const response = await axios.put(`${process.env.REACT_APP_REQ}companie`, data,{
                    headers: {
                    Authorization: `Bearer ${accessToken}`
                    }
                })
                if (response.status === 200) {
                buttonGetCompanies()
                setPutCompanieValueCallbackAuth(null)
                setDivPutCallbackAuth(false)
            }
        }catch (error) {
            console.log(error)
            console.log(error.response.data.msg)
        }finally {
            console.log('DEV - FIM PUT COMPANIE CALLBACK AUTH')
        }
    }
    

    const functionPutDesiredData = async() => {

        if(!putArrayToSend){
            setAlertContent('🔔 Verificar: Variáveis!')
            setShowAlertCompanie(true)
            //alert('🔔 Você não informou nenhuma variável, favor verificar!')
            return
        }
        const idAsInteger = parseInt(selectedGetCompanie, 10)
        const data = {
            id : idAsInteger,
            desired_data : putArrayToSend
        }
            try {
                const response = await axios.put(`${process.env.REACT_APP_REQ}companie`, data,{
                    headers: {
                    Authorization: `Bearer ${accessToken}`
                    }
                })
                if (response.status === 200) {
                buttonGetCompanies()
                setPutArrayToSend([])
                setDivPutDesiredData(false)
            }
        }catch (error) {
            console.log(error)
            console.log(error.response.data.msg)
        }finally {
            console.log('DEV - FIM PUT COMPANIE VARIAVEIS DESIRED DATA')
        }
    }
    
    
    const functionPutCompanieWhatsappToken = async() => {

        if(!putCompanieValueWppTokenAccess){
            setAlertContent('🔔 Verificar: Novo token!')
            setShowAlertCompanie(true)
            //alert('🔔 Você não informou um novo token, favor verificar!')
            return
        }
        const idAsInteger = parseInt(selectedGetCompanie, 10)
        const data = {
            id : idAsInteger,
            whatsapp_token_access : putCompanieValueWppTokenAccess
        }
            try {
                const response = await axios.put(`${process.env.REACT_APP_REQ}companie`, data,{
                    headers: {
                    Authorization: `Bearer ${accessToken}`
                    }
                })
                if (response.status === 200) {
                //console.log('response:', response)
                //console.log('data:', response.data)
                //console.log('status', response.status)
                buttonGetCompanies()
                setPutCompanieValueWppTokenAccess(null)
                setDivPutWppTokenAccess(false)
            }
        }catch (error) {
            console.log(error)
            console.log(error.response.data.msg)
        }finally {
            //console.log('DEV - FIM PUT COMPANIE WPP TOKEN')
        }
    }

    const functionPutCompanieWhatsappId = async() => {

        if(!putCompanieValueWppId){
            setAlertContent('🔔 Verificar: Um novo ID!')
            setShowAlertCompanie(true)
            //alert('🔔 Você não informou um novo ID, favor verificar!')
            return
        }
        const idAsInteger = parseInt(selectedGetCompanie, 10)
        const data = {
            id : idAsInteger,
            whatsapp_number_id : putCompanieValueWppId
        }
        //console.log('DEV - Início PUT COMPANIE WPP ID', data)
            try {
                const response = await axios.put(`${process.env.REACT_APP_REQ}companie`, data,{
                    headers: {
                    Authorization: `Bearer ${accessToken}`
                    }
                })
                if (response.status === 200) {
                //console.log('response:', response)
                //console.log('data:', response.data)
                //console.log('status', response.status)
                buttonGetCompanies()
                setPutCompanieValueWppId(null)
                setDivPutTokenAccessWppId(false)
            }
        }catch (error) {
            console.log(error)
            console.log(error.response.data.msg)
        }finally {
            //console.log('DEV - FIM PUT COMPANIE WPP ID')
        }
    }

    const functionPutChatEngine = async() => {

        if(!inputPutTechChoice){
            setAlertContent('🔔 Verificar: Tecnologia do chat!')
            setShowAlertCompanie(true)
            return
        }
        const idAsInteger = parseInt(selectedGetCompanie, 10)
        const data = {
            id : idAsInteger,
            chat_engine : inputPutTechChoice
        }
        //console.log('DEV - Início PUT COMPANIE WPP ID', data)
            try {
                const response = await axios.put(`${process.env.REACT_APP_REQ}companie`, data,{
                    headers: {
                    Authorization: `Bearer ${accessToken}`
                    }
                })
                if (response.status === 200) {
                //console.log('response:', response)
                //console.log('data:', response.data)
                //console.log('status', response.status)
                buttonGetCompanies()
                setInputPutTechChoice(null)
                setDivPutChatEngine(false)
            }
        }catch (error) {
            console.log(error)
            console.log(error.response.data.msg)
        }finally {
            //console.log('DEV - FIM PUT COMPANIE WPP ID')
        }
    }

    return (
        <div className='div_update'>
            <div className='div_update_0'>
                <div className='div_update_1'>
                    <EnzoMenu />
                    <EnzoMenuMobile />
                </div>
                <div className='div_update_2'>  
                    <div className='divColumGap16'>
                        <div className='div_update_title'>SEND - <b>COMPANIE</b></div>
                        <div className='div300pxW'><ButtonPadrao backgroundColor={'#03A8CC'} onClick={buttonShowNewCompanie} text={'CADASTRAR'}/></div>
                        {divNewCompanie && <div className='divColumGap16PadLeft'>
                            
                            <div className='div_update_labelTitle'><b>T R E I N A M E N T O:</b></div> 
                            <div className='div_update_label'><b>Informe o nome da Companie:</b></div>
                            <Input type={'text'} placeholder={'Informe o nome da Companie'} borderRange={'2px'} value={companieRegister} onChange={(e) => setCompanieRegister(e.target.value)} borderColor={'#222323'} />

                            <div className='div_update_label'><b>Insira o treinamento da Send:</b></div>
                            <div className='div_update_text'>Você pode inserir um arquivo ‘.txt’ ou inserir o treinamento em texto. Lembrando que o treinamento não pode conter mais de 14000 palavras. ♥</div>
                            <div className='div_update_buttons_row'>
                                <div className='div_update_buttons'><ButtonPadrao backgroundColor={'#b1bdc2'} onClick={buttonInsertFile} text={'ARQUIVO'}/></div>
                                <div className='div_update_buttons'><ButtonPadrao backgroundColor={'#b1bdc2'} onClick={buttonInsertText} text={'ESCREVER'}/></div>
                            </div>
                            {divInsertText && <div className='div_update_textarea'>
                                <Input type={'textarea'} borderRange={'2px'} placeholder={'Treinamento com até 14000 palavras'}  value={insertText} onChange={(e) => setInsertText(e.target.value)} borderColor={'#222323'} />
                                {wordCountText >= 14000 ? (
                                    <div>
                                        <div  className='div_red'>Contagem de Palavras: <b>{wordCountText}</b></div>
                                    </div>
                                    ) : (
                                    <div>
                                        <div>Contagem de Palavras: <b>{wordCountText}</b></div>
                                    </div>
                                )}
                            </div>}
                            {divInsertFile && <div>
                                <Input type={'file'} borderRange={'0px'} onChange={handleFileChange} inputRef={inputRefPost} accept={'.txt'} />
                                {wordCount >= 14000 ? (
                                    <div>
                                        <div  className='div_red'>Contagem de Palavras: <b>{wordCount}</b></div>
                                    </div>
                                    ) : (
                                    <div>
                                        <div>Contagem de Palavras: <b>{wordCount}</b></div>
                                    </div>
                                )}
                                
                            </div>}
                            


                            <div className='div_update_labelTitle'><b>W H A T S A P P  M E T A:</b></div> 
                            <div className='div_update_label'><b>Informe o token:</b></div> 
                            <Input type={'text'} placeholder={'Insira o token resgatado no Portal do Meta'} borderRange={'2px'} value={wppTokenAcess} onChange={(e) => setWppTokenAcess(e.target.value)} borderColor={'#222323'} />
							<div className='div_update_label'><b>Informe o ID:</b></div> 
                            <Input type={'text'} placeholder={'Insira o id resgatado no Portal do Meta'} borderRange={'2px'} value={wppNumberId} onChange={(e) => setWppNumberId(e.target.value)} borderColor={'#222323'} />


                            

                            <div className='div_update_labelTitle'><b>C A L L B A C K:</b></div> 
                            <div className='div_update_label'><b>Informe sua rota de callback:</b></div> 
                            <Input type={'text'} placeholder={'Insira sua rota de callback http://www.minhapi.com/callback'} borderRange={'2px'} value={callbackRoute} onChange={(e) => setCallbackRoute(e.target.value)} borderColor={'#222323'} />

                            <div className='div_update_label'><b>Informe caso sua rota de callback possua token:</b></div> 
                            <Input type={'text'} placeholder={'Insira o token resgatado no Portal do Meta'} borderRange={'2px'} value={callbackRouteToken} onChange={(e) => setCallbackRouteToken(e.target.value)} borderColor={'#222323'} />
                            
                            <div className='div_update_labelTitle'><b>V A R I Á V E I S:</b></div> 
                            <div className='div_update_label'><b>Cadastre aqui suas variáveis principais que poderão ser extraidas das conversas:</b></div>
                            <div className='div_update_text'>Aqui você poderá inserir as variáveis que poderão ser extraida das conversas. EXEMPLOS: nome completo, indicamos que você o cadastre como nome_completo, e endereço completo, indicamos que você o cadastre como endereco_completo.</div> 
                            <Input type={'text'} placeholder={'EXEMPLO: Nome completo você poderá inserir como nome_completo'} borderRange={'2px'} value={inputValue} onChange={(e) => setInputValue(e.target.value)} borderColor={'#222323'} />
                            <ButtonPadrao backgroundColor={'#b1bdc2'} onClick={addItem} text={'ADICIONAR'}/>
                            {/* Mostrar os itens do array */}
                            <ul>
                                {arrayToSend.map((item, index) => (
                                <li key={index}>{item}</li>
                                ))}
                            </ul>
                            <label className='div_update_label'><b>Escolha a tecnologia a ser utilizada:</b></label> 
                            <select id="techSelect" value={techChoice} onChange={handleSelectChange} className='input_branco_cinza'>
                                <option value="">Selecione...</option>
                                <option value="langchain">LANGCHAIN</option>
                                <option value="openai">OPENAI</option>
                            </select>
                           

                            
                            <div className='div_update_confirm'><ButtonPadrao backgroundColor={'#FF5C33'} onClick={buttonNewCompanie} disabled={loadingRegister} text={'CADASTRAR COMPANIE'}/></div>
                            {loadingRegister &&<LoadingImageRotate loadingImage={loadingRosa}  altLoadingImage={'Loading image'} width={'60px'} text={'C A D A S T R A N D O . . .'} />}
                        
                        </div>} 
                        {divErrorNewCompanie && <div>
                                <DivError codeError={errorNewCompanieCode} textCodeError={errorNewCompanieCodeText} textError={errorNewCompanieText.toUpperCase()} imgAlt={'Imagem robo error'} imgSrc={errorImgRoxo} imgClassName={'imgErrorWidth'}   />
                        </div>}
                        {showNotificationCompanie &&
                            <div className='div_Notification'>{notificationContent}</div>
                        }
                        {showAlertCompanie &&
                            <div className='div_Alert'>{alertContent}</div>
                        }

                        <div className='div300pxW'><ButtonPadrao backgroundColor={'#03A8CC'} onClick={buttonGetCompanies} disabled={loadingGetCompanies} text={'EXIBIR'}/></div>
                        {loadingGetCompanies && <LoadingImageRotate loadingImage={loadingRosa}  altLoadingImage={'Loading image'} width={'60px'} text={'C A R R E G A N D O . . .'} />}
                        <div className='divGeralMostraCompanie'>
                            {returnGetCompanie && returnGetCompanie.results &&
                                returnGetCompanie.results.map((item) => (
                                        <div className='divColumGap16'>
                                            <div key={item.id} onClick={() => handleItemClickGetCompanie(item.id, item.auth_token)} className='divTitleRegister'>
                                                <Img className={'iconTitleRegister'} imageUrl={iconRegister} alt={'Icone registro'} />
                                                <div className='textTitleRegister'><b>COMPANIE {item.id} - {item.name.toUpperCase()}</b></div>
                                            </div>
                                            {selectedGetCompanie === item.id && (
                                                <div className='divGeralMostraCompanie'>
                                                    <div className='div_update_labelTitle'><b>T R E I N A M E N T O:</b></div>
                                                    <ItemReturnEdit  textTitle={'Título da Companie:'} onClickFunction={handlePutCompanieName} divClassName={'divItemReturnEdit'} divClassNameItem={'itemReturnEditRow'} iconClassName={'iconItemReturnEdit'} iconLeftUrl={iconUrl} iconLeftAlt={'Icone URL'} textTitleClassName={'textReturnEditTitleBlack'} iconRightUrl={iconEditGray} iconRightAlt={'Icone Editar'} textButtonTitleClassName={'textItemReturnEditGray'} textButtonTitle={'Editar'} divClassNameItem2={'divpointer'}/>
                                                    <div className='textReturnEditInfo'><b>{item.name}</b></div>
                                                    {divPutName && 
                                                        <div className='divColumnGap16px'>
                                                            <div className='div_update_label'><b>Novo título da Companie:</b></div>
                                                            <input placeholder='Informe o novo título da Companie' className='inputCompanie' value={putCompanieValueName} onChange={(e) => setPutCompanieValueName(e.target.value)}/>
                                                            <button className='buttonCompanie' onClick={functionPutCompanieName}>ALTERAR</button>
                                                        </div>
                                                    }
                                                    <ItemReturnEdit onClickFunctionExpand={functionExpandModelTrainCompanie} textTitleClassNameExpand={'textItemReturnEditGray'} textTitleExpand={divModelTrainExpand ? 'OCULTAR' : 'EXPANDIR'}  textTitle={'Treinamento:'} onClickFunction={handlePutCompanieModelTrain} divClassName={'divItemReturnEdit'} divClassNameItem={'itemReturnEditRow'} iconClassName={'iconItemReturnEdit'} iconLeftUrl={iconTreinamento} iconLeftAlt={'Icone treinamento'} textTitleClassName={'textReturnEditTitleBlack'} iconRightUrl={iconEditGray} iconRightAlt={'Icone Editar'} textButtonTitleClassName={'textItemReturnEditGray'} textButtonTitle={'Editar'} divClassNameItem2={'divpointer'} />
                                                    <div className='textReturnEditInfo'>{divModelTrainExpand && <div><b>{item.model_train}</b></div>}</div>
                                                    {divPutModelTrain &&
                                                        <div className='divColumnGap90'>
                                                            <div className='div_update_label'><b>Inserir novo treinamento:</b></div>
                                                            <div className='div_update_textEdit'>Você pode inserir um arquivo ‘.txt’ ou inserir o treinamento em texto. Lembrando que o treinamento não pode conter mais de 14000 palavras. ♥</div> 
                                                            <div className='divPutRowGap16'>
                                                                <div className='div_update_buttons'><button className='buttonCompanieCinzaLittle' onClick={putButtonInsertFile}>ARQUIVO</button></div>
                                                                <div className='div_update_buttons'><button className='buttonCompanieCinzaLittle' onClick={putButtonInsertText}>ESCREVER</button></div>
                                                            </div>
                                                            {divPutCompanieValueModelTrainText &&
                                                                <div className='divColumnGap16px'>
                                                                    <div className='div_update_label'><b>Digite um novo treinamento:</b></div>
                                                                    <textarea placeholder='Digite um novo treinamento' className='inputCompanie100' value={putCompanieValueModelTrainText} onChange={(e) => setPutCompanieValueModelTrainText(e.target.value)}/>
                                                                    {putCompanieWordCount >= 14000 ? (
                                                                        <div>
                                                                            <div  className='div_red'>Contagem de Palavras: <b>{putCompanieWordCount}</b></div>
                                                                        </div>
                                                                        ) : (
                                                                        <div>
                                                                            <div>Contagem de Palavras: <b>{putCompanieWordCount}</b></div>
                                                                        </div>
                                                                    )}
                                                                    <button className='buttonCompanie100' onClick={functionPutCompanieModelTrain}>ALTERAR</button>
                                                                </div>
                                                            }
                                                            
                                                            {divPutCompanieValueModelTrainFile &&
                                                                <div className='divColumnGap16px'>
                                                                    <div className='div_update_label'><b>Insira um novo treinamento:</b></div>
                                                                    <input type='file' placeholder='Insira um novo treinamento' ref={inputRefPut} accept='.txt' onChange={handlePutFileChange}/>
                                                                    {putCompanieFileWordCount >= 14000 ? (
                                                                        <div>
                                                                            <div  className='div_red'>Contagem de Palavras: <b>{putCompanieFileWordCount}</b></div>
                                                                        </div>
                                                                        ) : (
                                                                        <div>
                                                                            <div>Contagem de Palavras: <b>{putCompanieFileWordCount}</b></div>
                                                                        </div>
                                                                    )}
                                                                    <button className='buttonCompanie100' onClick={functionPutCompanieModelTrain}>ALTERAR</button>
                                                                </div>
                                                            }
                                                        </div>
                                                    }
                                                    <ItemReturnEdit  textTitle={'Inserido em:'} divClassName={'divItemReturnEdit'} divClassNameItem={'itemReturnEditRow'} iconClassName={'iconItemReturnEdit'} iconLeftUrl={iconCalendar} iconLeftAlt={'Icone Calendario'} textTitleClassName={'textReturnEditTitleBlack'} iconRightUrl={iconEditGray} iconRightAlt={'Icone Editar'} textButtonTitleClassName={'textItemReturnEditGray'} textButtonTitle={'Editar'} divClassNameItem2={'displayNone'} />
                                                    <div className='textReturnEditInfo'><b>{formatDate(item.inserted)}</b></div>
                                                    <ItemReturnEdit  textTitle={'Token de autorização:'} divClassName={'divItemReturnEdit'} divClassNameItem={'itemReturnEditRow'} iconClassName={'iconItemReturnEdit'} iconLeftUrl={iconToken} iconLeftAlt={'Icone Token'} textTitleClassName={'textReturnEditTitleBlack'} iconRightUrl={iconEditGray} iconRightAlt={'Icone Editar'} textButtonTitleClassName={'textItemReturnEditGray'} textButtonTitle={'Editar'} divClassNameItem2={'displayNone'} />
                                                    <div className='textReturnEditInfo'><b>{item.auth_token}</b></div>
                                                    
                                                    {/* Se chat_engine não for mais APRESENTADA como uma prop de company retirar esse trecho RETIRAR DAQUI ****** */}
                                                    <ItemReturnEdit  textTitle={'Tecnologia escolhida:'} onClickFunction={handlePutChatEngine} divClassName={'divItemReturnEdit'} divClassNameItem={'itemReturnEditRow'} iconClassName={'iconItemReturnEdit'} iconLeftUrl={iconTech} iconLeftAlt={'Icone tecnologia'} textTitleClassName={'textReturnEditTitleBlack'} iconRightUrl={iconEditGray} iconRightAlt={'Icone Editar'} textButtonTitleClassName={'textItemReturnEditGray'} textButtonTitle={'Editar'} divClassNameItem2={'divpointer'} />
                                                    <div className='textReturnEditInfo'><b>{item.chat_engine.toUpperCase()}</b></div>
                                                    {divPutChatEngine &&
                                                        <div className='divColumnGap16px'>
                                                            <label className='div_update_label'><b>Altere a tecnologia:</b></label>
                                                            <div className='divMaxWid90'>
                                                                <select id="techSelect" value={inputPutTechChoice} onChange={handleSelectPutChange} className='input_branco_cinza'>
                                                                    <option value="">Selecione...</option>
                                                                    <option value="langchain">LANGCHAIN</option>
                                                                    <option value="openai">OPENAI</option>
                                                                </select>
                                                            </div>
                                                            <button className='buttonCompanie' onClick={functionPutChatEngine}>ALTERAR</button>
                                                        </div>
                                                    }
                                                    {/* Se chat_engine não for mais APRESENTADA como uma prop de company retirar esse trecho ATE AQUI ******  */}

                                                    <div className='div_update_labelTitle'><b>W H A T S A P P  M E T A:</b></div> 
                                                    <ItemReturnEdit  textTitle={'Token META:'} onClickFunction={handlePutCompanieWppToken}  divClassName={'divItemReturnEdit'} divClassNameItem={'itemReturnEditRow'} iconClassName={'iconItemReturnEdit'} iconLeftUrl={iconToken} iconLeftAlt={'Icone Token'} textTitleClassName={'textReturnEditTitleBlack'} iconRightUrl={iconEditGray} iconRightAlt={'Icone Editar'} textButtonTitleClassName={'textItemReturnEditGray'} textButtonTitle={'Editar'} divClassNameItem2={'divpointer'} />
                                                    <div className='textReturnEditInfo'><b>{item.whatsapp_token_access}</b></div>
                                                    {divPutWppTokenAccess &&
                                                        <div className='divColumnGap16px'>
                                                            <div className='div_update_label'><b>Informe um novo token:</b></div>
                                                            <input placeholder='Informe o novo token do Whatsapp Meta' className='inputCompanie' value={putCompanieValueWppTokenAccess} onChange={(e) => setPutCompanieValueWppTokenAccess(e.target.value)}  />
                                                            <button className='buttonCompanie' onClick={functionPutCompanieWhatsappToken}>ALTERAR</button>
                                                        </div>
                                                    }
                                                    <ItemReturnEdit  textTitle={'ID do número META:'} onClickFunction={handlePutCompanieWppId}  divClassName={'divItemReturnEdit'} divClassNameItem={'itemReturnEditRow'} iconClassName={'iconItemReturnEdit'} iconLeftUrl={iconId} iconLeftAlt={'Icone ID'} textTitleClassName={'textReturnEditTitleBlack'} iconRightUrl={iconEditGray} iconRightAlt={'Icone Editar'} textButtonTitleClassName={'textItemReturnEditGray'} textButtonTitle={'Editar'} divClassNameItem2={'divpointer'} />
                                                    <div className='textReturnEditInfo'><b>{item.whatsapp_number_id}</b></div>
                                                    {divPutTokenAccessWppId &&
                                                        <div className='divColumnGap16px'>
                                                            <div className='div_update_label'><b>Informe um novo ID:</b></div>
                                                            <input placeholder='Informe o novo ID Whatsapp Meta' className='inputCompanie' value={putCompanieValueWppId} onChange={(e) => setPutCompanieValueWppId(e.target.value)}  />
                                                            <button className='buttonCompanie' onClick={functionPutCompanieWhatsappId}>ALTERAR</button>
                                                        </div>
                                                    }
                                                    <div className='div_update_labelTitle'><b>C A L L B A C K:</b></div> 
                                                    <ItemReturnEdit  textTitle={'URL:'} onClickFunction={handlePutCompanieCallbackUrl}  divClassName={'divItemReturnEdit'} divClassNameItem={'itemReturnEditRow'} iconClassName={'iconItemReturnEdit'} iconLeftUrl={iconUrl} iconLeftAlt={'Icone URL'} textTitleClassName={'textReturnEditTitleBlack'} iconRightUrl={iconEditGray} iconRightAlt={'Icone Editar'} textButtonTitleClassName={'textItemReturnEditGray'} textButtonTitle={'Editar'} divClassNameItem2={'divpointer'}/>
                                                    <div className='textReturnEditInfo'><b>{item.callback_url}</b></div>
                                                    {divPutCallbackUrl &&
                                                        <div className='divColumnGap16px'>
                                                            <div className='div_update_label'><b>Informe uma nova rota:</b></div>
                                                            <input placeholder='Informe uma nova rota de callback' className='inputCompanie' value={putCompanieValueCallbackUrl} onChange={(e) => setPutCompanieValueCallbackUrl(e.target.value)}  />
                                                            <button className='buttonCompanie' onClick={functionPutCompanieCallbackUrl}>ALTERAR</button>
                                                        </div>
                                                    }
                                                    <ItemReturnEdit  textTitle={'Token de autorização:'} onClickFunction={handlePutCompanieCallbackAuth}  divClassName={'divItemReturnEdit'} divClassNameItem={'itemReturnEditRow'} iconClassName={'iconItemReturnEdit'} iconLeftUrl={iconToken} iconLeftAlt={'Icone Token'} textTitleClassName={'textReturnEditTitleBlack'} iconRightUrl={iconEditGray} iconRightAlt={'Icone Editar'} textButtonTitleClassName={'textItemReturnEditGray'} textButtonTitle={'Editar'} divClassNameItem2={'divpointer'} />
                                                    <div className='textReturnEditInfo'><b>{item.callback_auth}</b></div>
                                                    {divPutCallbackAuth &&
                                                        <div className='divColumnGap16px'>
                                                            <div className='div_update_label'><b>Informe um novo token:</b></div>
                                                            <input placeholder='Informe um novo token' className='inputCompanie' value={putCompanieValueCallbackAuth} onChange={(e) => setPutCompanieValueCallbackAuth(e.target.value)}  />
                                                            <button className='buttonCompanie' onClick={functionPutCompanieCallbackAuth}>ALTERAR</button>
                                                        </div>
                                                    }
                                                    <div className='div_update_labelTitle'><b>V A R I Á V E I S:</b></div> 
                                                    <ItemReturnEdit  textTitle={'Variáveis cadastradas:'}  onClickFunction={handlePutCompanieDesiredData}  divClassName={'divItemReturnEdit'} divClassNameItem={'itemReturnEditRow'} iconClassName={'iconItemReturnEdit'} iconLeftUrl={iconList} iconLeftAlt={'Icone lista'} textTitleClassName={'textReturnEditTitleBlack'} iconRightUrl={iconEditGray} iconRightAlt={'Icone Editar'} textButtonTitleClassName={'textItemReturnEditGray'} textButtonTitle={'Editar'} divClassNameItem2={'divpointer'} />
                                                    <div className='textReturnEditInfo'><b>{item.desired_data}</b></div>
                                                    {divPutDesiredData &&
                                                        <div className='divColumnGap16px'>
                                                            <div className='div_update_label'><b>Cadastre aqui suas variáveis principais que poderão ser extraidas das conversas:</b></div>
                                                            <div className='div_update_textEdit'>Lembrando que aqui você poderá inserir as variáveis que poderão ser extraida das conversas. EXEMPLOS: nome completo, indicamos que você o cadastre como nome_completo, e endereço completo, indicamos que você o cadastre como endereco_completo.</div> 
                                                            <input placeholder='EXEMPLO: Nome completo você poderá inserir como nome_completo' className='inputCompanie' value={putDesiredData} onChange={(e) => setPutDesiredData(e.target.value)}  />
                                                            <button className='buttonCompanieCinza' onClick={putAddItem}>ADICIONAR</button>
                                                            {/* Mostrar os itens do array */}
                                                            <ul>
                                                                {putArrayToSend.map((item, index) => (
                                                                <li key={index}>{item}</li>
                                                                ))}
                                                            </ul>
                                                            <div/>
                                                            <div/>
                                                            <button className='buttonCompanie' onClick={functionPutDesiredData}>ALTERAR</button>
                                                        </div>
                                                    }
                                                    
                                                </div>
                                            )}
                                        </div>
                                    ))
                            }
                            {divErrorGetCompanie && <div>
                                <DivError codeError={errorCompanieCode} textCodeError={errorCompanieCodeText} textError={errorCompanieText.toUpperCase()} imgAlt={'Imagem robo error'} imgSrc={errorImgRoxo} imgClassName={'imgErrorWidth'}   />
                            </div>}   
                            



                            
                        </div>
                       

                    </div>
                    





                    <div className='div_update_button_logo'>
                        <div><Logo width={'200px'} imageUrl={logoPreta} alt={'Logo cor Preto'} /></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EnzoCompanie