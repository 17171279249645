import React from 'react';
import styles from './footer.module.css';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerctt}>

        {/* Logo */}
        <div>
          <img 
            src="/new/logo/logo.png" 
            alt="Logo Send IA" 
            width="100" 
            height="100" 
            className={styles.imglogo}
          />
        </div>

        {/* Informações de contato */}
        <div>
          <p>
            <a href="mailto:contato@send.ia">contato@tgdsolucoes.com.br</a><br />
            <a href="tel:+5511999999999">+55(11) 5194-5041</a>
          </p>
        </div>

        {/* Direitos autorais */}
        <div>
          <p>©️ 2024 Send IA. Todos os direitos reservados.</p>
          <p>ASCOM MONTAGENS - CNPJ 05.816.317/0001-14</p>
        </div>

        {/* Endereço (invisível na tela, visível para mecanismos de busca) */}
        <address className={styles.srOnly}>
          Avenida Washington Luis, 4000<br />
          CEP 13.042-105, Vila Marieta<br />
          Campinas, SP
        </address>

        {/* Ícones de redes sociais 
          <div>
            <a href="https://wa.me/5511999999999" target="_blank" rel="noopener noreferrer">
              <img 
                src="/new/icons/whatsapp.png" 
                alt="Ícone do WhatsApp" 
                width="30" 
                height="30"  
              />
            </a>
            <a href="https://www.instagram.com/seu_perfil" target="_blank" rel="noopener noreferrer">
              <img 
                src="/new/icons/insta.png" 
                alt="Ícone do Instagram" 
                width="30" 
                height="30"  
              />
            </a>
          </div>
        */}
      </div>
    </footer>
  );
};

export default Footer;
