import React from 'react';
import styles from './price.module.css';

const Price = () => {
    const planoStarter = {
        title: 'Plano Starter',
        subtitle: 'Ideal para começar! Gerencie até 300 ou 1000 atendimentos por mês com eficiência e simplicidade.',
        content: [
            {
                name: 'Intro',
                price: 'R$ 98/mês',
                features: [
                    '300 atendimentos/mês'
                ],
                action: 'Escolher este plano',
            },
            {
                name: 'Boost',
                price: 'R$ 318/mês',
                features: [
                    '1000 atendimentos/mês'
                ],
                action: 'Escolher este plano',
            }
        ]
    }

    const planoPro = {
        title: 'Plano Pro',
        subtitle: 'Perfeito para quem quer mais! Atenda e aborde seus clientes com até 300 ou 1000 interações mensais.',
        content: [
            {
                name: 'Essential',
                price: 'R$ 338/mês',
                features: [
                    '300 atendimentos/mês',
                    '300 abordagens/mês'
                ],
                action: 'Escolher este plano',
            },
            {
                name: 'Turbo',
                price: 'R$ 898/mês',
                features: [
                    '1000 atendimentos/mês',
                    '1000 abordagens/mês'
                ],
                action: 'Escolher este plano',
            }
        ]
    }

    const planoUltimate = {
        title: 'Plano Ultimate',
        subtitle: 'A solução completa! Atenda, aborde e faça pesquisas com até 300 ou 1000 interações e colete feedbacks valiosos.',
        content: [
            {
                name: 'Basic',
                price: 'R$ 548/mês',
                features: [
                    '300 atendimentos/mês',
                    '300 abordagens/mês',
                    '300 pesquisas/mês'
                ],
                action: 'Escolher este plano',
            },
            {
                name: 'Power',
                price: 'R$ 1.468/mês',
                features: [
                    '1000 atendimentos/mês',
                    '1000 abordagens/mês',
                    '1000 pesquisas/mês'
                ],
                action: 'Escolher este plano',
            }
        ]
    }

    return (

        <div className={styles.mom}>
            <div className={styles.pricebg}>
                <div className={styles.pricett}>
                    <div className={styles.titlediv}>
                        <div className={styles.dvtct}>
                            <img src="/new/wppimg.png" alt="Ilustração rotina cliente" />
                            <div className='div100 color1'>
                                <h2>Nossos planos para seu negócio! :)</h2>
                            </div>
                        </div>
                    </div>

                    <br/>
                    <br/>
                    {/* Renderização do planoStarter */}
                    <div className={styles.planContainer}>
                        <div className={styles.textdv}>
                            <h4 className='color3'>{planoStarter.title}</h4>
                            <p>{planoStarter.subtitle}</p>
                        </div>
                        <div className={styles.planOptions}>
                            {planoStarter.content.map((option, index) => (
                                <div className={styles.card1}>
                                    <div key={index} className={styles.planOption1}>
                                        <p className='titleprice'>{option.name}</p>
                                        <p className='color3 pricegeral'>{option.price}</p>
                                        
                                        {option.features.map((feature, i) => (
                                            <div key={i} className={styles.itens}>
                                                <img src="/new/arrowplano.png" alt="Seta" />
                                                <div >{feature}</div>
                                            </div>
                                        ))}
                                        <button className={styles.bt3}>{option.action}</button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                
            </div>
            <div className={styles.pricebg2}>
                <div className={styles.pricett}>
                    {/* Renderização do planoPro */}
                    <div className={styles.planContainer}>
                        <div className={styles.textdv}>
                            <h4 className='color2'>{planoPro.title}</h4>
                            <p>{planoPro.subtitle}</p>
                        </div>
                        <div className={styles.planOptions}>
                            {planoPro.content.map((option, index) => (
                                <div className={styles.card2}>
                                    <div key={index} className={styles.planOption2}>
                                        <p className='titleprice'>{option.name}</p>
                                        <p className='color2 pricegeral'>{option.price}</p>
                                        {option.features.map((feature, i) => (
                                            <div key={i} className={styles.itens}>
                                                <img src="/new/arrowplano.png" alt="Seta" />
                                                <div >{feature}</div>
                                            </div>
                                        ))}
                                        <button className={styles.bt2}>{option.action}</button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.pricebg3}>
                <div className={styles.pricett}>

                    {/* Renderização do planoUltimate */}
                    <div className={styles.planContainer}>
                        <div className={styles.textdv}>
                            <h4 className='color1'>{planoUltimate.title}</h4>
                            <p>{planoUltimate.subtitle}</p>
                        </div>
                        <div className={styles.planOptions}>
                            {planoUltimate.content.map((option, index) => (
                                <div className={styles.card3}>
                                    <div key={index} className={styles.planOption3}>
                                        <p className='titleprice'>{option.name}</p>
                                        <p  className='color1 pricegeral'>{option.price}</p>
                                        {option.features.map((feature, i) => (
                                            <div key={i} className={styles.itens}>
                                                <img src="/new/arrowplano.png" alt="Seta" />
                                                <div >{feature}</div>
                                            </div>
                                        ))}
                                        <button className={styles.bt1}>{option.action}</button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Price;
