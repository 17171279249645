import './talk.css'
import { Navigate } from 'react-router-dom'
import { useState, useEffect, useContext, useRef } from 'react'
import { AuthContext } from '../../context/AuthContext'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import Logo from '../../components/Logo'
import logoPreta from '../img/send.png'
import EnzoMenu from '../../components/EnzoMenu'
import EnzoMenuMobile from '../../components/EnzoMenuMobile'
import loadingRosa from '../img/send.png'
import loadingBranco from '../img/loading_p_branco.png'
import LoadingImageRotate from '../../components/LoadingImageRotate'
import DivError from '../../components/DivError'
import errorImgOrange from '../img/send.png'

const EnzoTalk = () => {

    const [returnGetCompanieTalk, setReturnGetCompanieTalk] = useState(null)
    const [selectedAuthTokenCompanieTalk, setSelectedAuthTokenCompanieTalk] = useState(null)
    const [selectedNameCompanie, setSelectedNameCompanie] = useState(null)
    const [arrayMessages, setArrayMessages] = useState([])
    const [valueSendMessage, setValueSendMessage] = useState(null)
    const [divShowChat, setDivShowChat] = useState(false)
    const [chatSelectedId, setChatSelectedId] = useState(null)
    const [loadingStartChat, setLoadingStartChat] = useState(false)
    const [loadingSendMsg, setLoadingSendMsg] = useState(false)
    const [contentTalkIa, setContentTalkIa] = useState(true)
    const [showNotificationCompanie, setShowNotificationCompanie] = useState(false)
    const [showAlertCompanie, setShowAlertCompanie] = useState(false)
	const [notificationContent, setNotificationContent] = useState(null)
	const [alertContent, setAlertContent] = useState(null)
    const { accessToken } = useContext(AuthContext)
    const navigate = useNavigate()
    const inputRef = useRef()
    const [divErrorGetCompanie, setDivErrorGetCompanie] = useState(false)
    const [errorCompanieCode, setErrorCompanieCode] = useState(null) 
    const [errorCompanieCodeText, setErrorCompanieCodeText] = useState(null)
    const [errorCompanieText, setErrorCompanieText] = useState(null)

	
    useEffect(() => {
        if(!accessToken){
            <Navigate to="/login" />
        }
    }, [accessToken])


    {/* FEATURE */}
    const [chatDados, setChatDados] = useState([])
    const [chatChave, setChatChave] = useState('')
    const [chatValor, setChatValor] = useState('')
    

    
    useEffect(() => {
        if (accessToken) {
            console.log('Criando página enzotalk, GET com este accesstoken:',accessToken)
            axios.get(`${process.env.REACT_APP_REQ}companie`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            .then(response => {
                //console.log('Deu bom, response:', response.data)
                setReturnGetCompanieTalk(response.data)
            })
            .catch(error => {
                console.error('DEV - Erro na solicitação GET para clientdata:', error)
                setErrorCompanieText(error.response.data.msg)
                setErrorCompanieCodeText(error.response.statusText)
                setErrorCompanieCode(error.response.status)
                setDivErrorGetCompanie(true)
                if (error.response && error.response.status === 401) {
                    navigate('/logout')
                }
                // TIREI POIS ESTA LEVANDO P LOGIN EM CASO DE NÃO EXISTIR COMPANIE AINDA navigate('/login')
            })
        }
    }, [accessToken])

    useEffect(() => {

        if (showNotificationCompanie) {
        const timeoutId = setTimeout(() => {
            setShowNotificationCompanie(false)
            setNotificationContent('')
        }, 2000)

        return () => clearTimeout(timeoutId)
        }
    }, [showNotificationCompanie])


    useEffect(() => {

        if (showAlertCompanie) {
        const timeoutId = setTimeout(() => {
            setShowAlertCompanie(false)
            setAlertContent('')
        }, 2000)

        return () => clearTimeout(timeoutId)
        }
    }, [showAlertCompanie])

    const handleItemClickGetCompanie = (itemAuthToken, nameCompanie) => {
        setSelectedAuthTokenCompanieTalk(itemAuthToken)
        setSelectedNameCompanie(nameCompanie)
    }

    const handleSelectChange = (selectedAuthToken) => {
        // Encontre o item correspondente ao auth_token selecionado
        const selectedItem = returnGetCompanieTalk.results.find(
          (item) => item.auth_token === selectedAuthToken
        )
        if (selectedItem) {
          handleItemClickGetCompanie(selectedItem.auth_token, selectedItem.name)
        }
    }
   
    
    const resetButtonStartChat = () => {
        setLoadingSendMsg(false)
        setArrayMessages([])
        setDivShowChat(false)
        setValueSendMessage('')
    }
    const functionButtonStartChat = async() => {

        if(!selectedAuthTokenCompanieTalk){
            setAlertContent('🔔 Verificar: Seleção companie!')
            setShowAlertCompanie(true)
            //alert('Você não selecionou uma Companie, favor verificar!')
            return
        }
        resetButtonStartChat()
        let data = {}
        if (chatDados.length > 0) {
            data.data = chatDados.reduce((acc, curr) => {
                acc[curr.chave] = curr.valor
                return acc
            }, {})
        }
        //const idAsInteger = parseInt(selectedGetCompanie, 10)
        //console.log('DEV - Início CHAT POST // token a ser enviado no header:', selectedAuthTokenCompanieTalk)
        console.log('chatDados pré envio:', chatDados)
        setLoadingStartChat(true)
            try {
                const response = await axios.post(`${process.env.REACT_APP_REQ}chat`, data,{
                    headers: {
                        'companie-token': selectedAuthTokenCompanieTalk
                    }
                })
                if (response.status === 200) {
                //console.log('response:', response)
                //console.log('data:', response.data)
                //console.log('status', response.status)
                //console.log('data.msg:', response.data.msg)
                //console.log('data.id:', response.data.id)
                setChatSelectedId(response.data.id)
                setDivShowChat(true)
                setContentTalkIa(false)
            }
        }catch (error) {
            console.log(error)
            console.log(error.response.data.msg)
            if (error.response && error.response.status === 401) {
                navigate('/logout')
            }
        }finally {
            //console.log('DEV - FIM INICIAR CHAT POST ')
            setLoadingStartChat(false)
        }
    }

    const functionFocusInput = () => {
        setTimeout(() => {
            if (inputRef.current) {
                inputRef.current.focus()
            }
        }, 0)
    }
     
    const functionButtonSendMsg = async() => {

        if(!valueSendMessage){
            setAlertContent('🔔 Verificar: Mensagem!')
            setShowAlertCompanie(true)
            //alert('Você não inseriu um texto, favor verificar!')
            return
        }
        const now = new Date()
        const timestamp = `${now.toLocaleDateString()} ${now.toLocaleTimeString()}`

        setLoadingSendMsg(true)

        let data = {
            chat_id: chatSelectedId,
            msg: valueSendMessage
        }
        
        const newMessage = { user: 'Você:', content: valueSendMessage, time: timestamp }
        setArrayMessages(prevMessages => [...prevMessages, newMessage])
        
        setValueSendMessage('')
        //console.log('DEV - Início ENVIO CHAT POST // data:', data)
            try {
                const response = await axios.post(`${process.env.REACT_APP_REQ}message`, data,{
                    headers: {
                        'companie-token': selectedAuthTokenCompanieTalk
                    }
                })
                if (response.status === 200) {
                //console.log('response:', response)
                //console.log('data:', response.data)
                //console.log('status', response.status)
                //console.log('data.ia_response:', response.data.ia_response)
                const newTimestamp = `${now.toLocaleDateString()} ${now.toLocaleTimeString()}`
                const receivedMessage = { user: 'Y A N:', content: response.data.ia_response, time: newTimestamp }
                setArrayMessages(prevMessages => [...prevMessages, receivedMessage])
            }
        }catch (error) {
            console.log(error)
            console.log(error.response.data.msg)
        }finally {
            //console.log('DEV - FIM ENVIO CHAT POST')
            setLoadingSendMsg(false)
            functionFocusInput()
        }
        
    }
 
    const handleCloseChat = async() => {

        let data = {
            id: chatSelectedId,
            finished: true
        }
        
        //console.log('DEV - Início CLOSE CHAT PUT // data:', data)
            try {
                const response = await axios.put(`${process.env.REACT_APP_REQ}chat`, data,{
                    headers: {
                        'companie-token': selectedAuthTokenCompanieTalk
                    }
                })
                if (response.status === 200) {
                //console.log('response:', response)
                //console.log('data:', response.data)
                //console.log('status', response.status)
                //console.log('data.ia_response:', response.data.ia_response)
                resetButtonStartChat()
                setNotificationContent('🔔 Chat finalizado!')
                setShowNotificationCompanie(true)
                //alert('Chat finalizado!')
                setContentTalkIa(true)
                setChatDados([])
            }
        }catch (error) {
            console.log(error)
            console.log(error.response.data.msg)
        }finally {
            //console.log('DEV - FIM CLOSE CHAT PUT')
        }
    }
    {/* FEATURE 05/04 */}
    const adicionarParChaveValor = () => {
        if (chatChave && chatValor) {
            setChatDados([...chatDados, { chave: chatChave, valor: chatValor }])
            setChatChave('')
            setChatValor('')
        } else {
            alert('Favor inserir chave e valor. ♥')
        }
    }
    useEffect(() => {
        console.log('Atualização variável com chave e valor chatDados:', chatDados)
    }, [chatDados])

    return (
        <div className='div_talk'>
            <div className='div_talk_1'>
                <EnzoMenu />
                <EnzoMenuMobile />
            </div>
            <div className='div_talk_2'>
                <div className='div_talk_2_selection'>
                    <div className='div_talk_2_title'>FALE COM A <b> SEND </b></div>
                    {returnGetCompanieTalk && returnGetCompanieTalk.results && returnGetCompanieTalk.results.length > 0 ? (
                        <div>
                            {contentTalkIa &&
                                <div className='div_talk_2_select_companie'>
                                    <div className='div_talk_2_subtitle'><b>Selecione uma Companie:</b></div>
                                    {/*returnGetCompanieTalk.results.map((item) => (
                                        <div className={item.auth_token === selectedAuthTokenCompanieTalk ? 'div_talk_2_selected_item' : 'div_talk_2_selection_item'} onClick={() => handleItemClickGetCompanie(item.auth_token, item.name)}><b>{item.name.toUpperCase()}</b></div>
                                    ))*/}
                                    <select className='selectGeral' onChange={(e) => handleSelectChange(e.target.value)}>
                                        <option value="" selected={!selectedAuthTokenCompanieTalk}>Selecione uma opção</option>
                                        {returnGetCompanieTalk.results.map((item) => (
                                            <option
                                            key={item.auth_token}
                                            value={item.auth_token}
                                            selected={item.auth_token === selectedAuthTokenCompanieTalk}
                                            >
                                            {item.name.toUpperCase()}
                                            </option>
                                        ))}
                                    </select>
                                    <div className='colorWhite'><b>Item não obrigatório:</b></div>
                                    <div className='divDataChats'>
                                        <input type='text' placeholder='Informe uma chave' className='inputChats' onChange={(e) => setChatChave(e.target.value)} value={chatChave}/>
                                        <input type='text' placeholder='Informe uma valor' className='inputChats' onChange={(e) => setChatValor(e.target.value)} value={chatValor}/>
                                        <button className='buttonChats'  onClick={adicionarParChaveValor}>Inserir</button>
                                    </div>
                                    {chatDados.map((item, index) => (
                                        <li key={index} className='colorWhite'>{item.chave}: {item.valor}</li>
                                    ))}
                                    <button className='div_talk_2_butom' disabled={loadingStartChat} onClick={functionButtonStartChat}><b>INICIAR CONVERSA</b></button>
                                    {loadingStartChat && <LoadingImageRotate loadingImage={loadingBranco}  altLoadingImage={'Loading image'} width={'60px'} text={'C A R R E G A N D O . . .'} />}
                                </div>
                            }
                            
                        </div>
                    ) : (
                        <div>
                            {divErrorGetCompanie && <div>
                                <DivError codeError={errorCompanieCode} textCodeError={errorCompanieCodeText} textError={errorCompanieText.toUpperCase()} imgAlt={'Imagem robo error'} imgSrc={errorImgOrange} imgClassName={'imgErrorWidth'}   />
                            </div>} 
                        </div>
                    )}
                </div>
                <div>
                    {divShowChat &&
                        <div className='div_talk_2_inputbuttonLoading'>
                            <div className='dik_talk_2_title_buttonCloseChat'>
                                <div className='div_talk_2_titlePurple'>C O M P A N I E : <b>{selectedNameCompanie.toUpperCase()}</b></div>
                                <button className='div_talk_2_buttonCloseChat' onClick={handleCloseChat}><b>ENCERRAR CHAT</b></button>
                            </div>
                            <div className='div_scrool_col'>
                                {arrayMessages && arrayMessages.map((message, index) => {
                                    const className = message.user === 'Y A N:' ? 'div_talk_2_receivedMsg' : 'div_talk_2_sendMsg'
                                    return (
                                        
                                            <div key={index} className={className}>
                                                <div className='textjustify'><b>{message.user}</b></div>
                                                <div className='textjustify'>{message.content}</div>
                                            </div>
                                    )
                                })}
                            </div>
                            {loadingSendMsg && <LoadingImageRotate loadingImage={loadingRosa}  altLoadingImage={'Loading image'} width={'60px'} text={'S e n d...'} />}
                            <div className='div_talk_2_inputbutton'>
                                <input type='text' placeholder='Digite sua mensagem aqui...' className='div_talk_2_inputbutton_input' 
                                    ref={inputRef}
                                    disabled={loadingSendMsg}
                                    value={valueSendMessage} 
                                    onChange={(e) => setValueSendMessage(e.target.value)}
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            //console.log('Pressionei enter')
                                            functionButtonSendMsg()
                                        }
                                    }}
                                />
                                <button className='div_talk_2_inputbutton_button' disabled={loadingSendMsg} onClick={functionButtonSendMsg}>ENVIAR</button>
                            </div>
                        </div>
                        
                    }
                </div>
                {showNotificationCompanie &&
                    <div className='div_Notification'>{notificationContent}</div>
                }
                {showAlertCompanie &&
                    <div className='div_Alert'>{alertContent}</div>
                }
                
                <div className='div_talk_2_logo'>
                    <div><Logo width={'200px'} imageUrl={logoPreta} alt={'Logo cor Preto'} /></div>
                </div>
            </div>
        </div>
    )
}

export default EnzoTalk
