// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.first_first__88\\+DV{
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #090909;
}

.first_cttfirst__kynPN{
    position: relative;
    max-width: 1200px;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 2rem;
    align-items: center;
    justify-content: center;
    padding: 1% 0;
}

.first_textctt__r7F-W{
    max-width: 600px;
}

.first_text1__5sD47{
    font-size: 20px;
    font-weight: 700;
    color:#8933FF;
}


.first_text2__amJGg{
    font-size: 32px;
    font-weight: 700;
    color:#FF5C33;
}



@media(max-width: 1000px){
    .first_cttfirst__kynPN{
        flex-direction: column;
        padding: 5% 10% 5% 10%;
        gap: 0.5rem;
    }
    .first_text2__amJGg{
        font-size: 24px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/FirstContent/first.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,yBAAyB;AAC7B;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;AACjB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,aAAa;AACjB;;;AAGA;IACI,eAAe;IACf,gBAAgB;IAChB,aAAa;AACjB;;;;AAIA;IACI;QACI,sBAAsB;QACtB,sBAAsB;QACtB,WAAW;IACf;IACA;QACI,eAAe;IACnB;AACJ","sourcesContent":[".first{\n    position: relative;\n    width: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    background-color: #090909;\n}\n\n.cttfirst{\n    position: relative;\n    max-width: 1200px;\n    width: 100%;\n    display: flex;\n    flex-direction: row;\n    gap: 2rem;\n    align-items: center;\n    justify-content: center;\n    padding: 1% 0;\n}\n\n.textctt{\n    max-width: 600px;\n}\n\n.text1{\n    font-size: 20px;\n    font-weight: 700;\n    color:#8933FF;\n}\n\n\n.text2{\n    font-size: 32px;\n    font-weight: 700;\n    color:#FF5C33;\n}\n\n\n\n@media(max-width: 1000px){\n    .cttfirst{\n        flex-direction: column;\n        padding: 5% 10% 5% 10%;\n        gap: 0.5rem;\n    }\n    .text2{\n        font-size: 24px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"first": `first_first__88+DV`,
	"cttfirst": `first_cttfirst__kynPN`,
	"textctt": `first_textctt__r7F-W`,
	"text1": `first_text1__5sD47`,
	"text2": `first_text2__amJGg`
};
export default ___CSS_LOADER_EXPORT___;
