import './EnzoChats.css'
import { Navigate } from 'react-router-dom'
import { useState, useEffect, useContext } from 'react'
import { AuthContext } from '../../context/AuthContext'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import Logo from '../../components/Logo'
import logoPreta from '../img/young_preto.png'
import EnzoMenu from '../../components/EnzoMenu'
import EnzoMenuMobile from '../../components/EnzoMenuMobile'
import { Modal, Button } from 'react-bootstrap'
import DivError from '../../components/DivError'
import errorImgOrange from '../img/young404orange.png'
import iconWpp from '../../icons/wpp.png'
import iconUrl from '../../icons/url.png'
import iconFinalizado from '../../icons/finished.png'
import iconAndamento from '../../icons/andamento.png'

const EnzoChats = () => {

    const [returnGetCompanieChats, setReturnGetCompanieChats] = useState(null)
    const [returnGetChats, setReturnGetChats] = useState(null)
    const [selectedAuthTokenCompanieChats, setSelectedAuthTokenCompanieChats] = useState(null)
    const [selectedChatId, setSelectedChatId] = useState(null)
    const [selectedServiceId, setSelectedServiceId] = useState(null)
    const [selectedFinishedInfo, setSelectedFinishedInfo] = useState(null)
    const [divStartChatShow, setDivStartChatShow] = useState(true)
    const [divShowModelTrainChats, setDivShowModelTrainChats] = useState(false)
    const [returnGetChatsId, setReturnGetChatsId] = useState(null)
    const [showNotificationCompanie, setShowNotificationCompanie] = useState(false)
    const [showAlertCompanie, setShowAlertCompanie] = useState(false)
	const [notificationContent, setNotificationContent] = useState(null)
	const [alertContent, setAlertContent] = useState(null)
    const [divErrorGetCompanie, setDivErrorGetCompanie] = useState(false)
    const [errorCompanieCode, setErrorCompanieCode] = useState(null) 
    const [errorCompanieCodeText, setErrorCompanieCodeText] = useState(null)
    const [errorCompanieText, setErrorCompanieText] = useState(null)
    
    const navigate = useNavigate()
    
    const { accessToken } = useContext(AuthContext)

    
    if (!accessToken) {
        return <Navigate to="/login" />
    }
    

    useEffect(() => {
        if (accessToken) {
            axios.get(`${process.env.REACT_APP_REQ}companie`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            .then(response => {
                //console.log('Deu bom, response:', response.data)
                setReturnGetCompanieChats(response.data)
            })
            .catch(error => {
                console.error('DEV - Erro na solicitação GET para clientdata:', error)
                setErrorCompanieText(error.response.data.msg)
                setErrorCompanieCodeText(error.response.statusText)
                setErrorCompanieCode(error.response.status)
                setDivErrorGetCompanie(true)
                if (error.response && error.response.status === 401) {
                    navigate('/logout')
                }
                // TIREI POIS ESTA LEVANDO P LOGIN EM CASO DE NÃO EXISTIR COMPANIE AINDA navigate('/login')
            })
        }
    }, [accessToken])

    

    useEffect(() => {
        if (selectedChatId !== null && selectedAuthTokenCompanieChats) {
            const interval = setInterval(() => {
            // Faz a requisição GET
            axios.get(`${process.env.REACT_APP_REQ}message?chat_id=${selectedChatId}`, {
              headers: {
                'companie-token': selectedAuthTokenCompanieChats
              }
            })
              .then(response => {
                //console.log('Deu bom, response  USEEFFECT CHAT SELECIONADO:', response.data)
                setReturnGetChatsId(response.data)
                setDivStartChatShow(false)
              })
              .catch(error => {
                console.error('DEV - Erro na solicitação GET para USEEFFECT CHAT SELECIONADO:', error)
                setDivStartChatShow(false)
                /*if (error.response && error.response.status === 401) {
                  navigate('/logout')
                }*/
              })
          }, 3000)
    
          // Remove o interval quando o componente for desmontado
          return () => clearInterval(interval)
        }
    }, [selectedChatId, selectedAuthTokenCompanieChats])


    useEffect(() => {

        if (showNotificationCompanie) {
        const timeoutId = setTimeout(() => {
            setShowNotificationCompanie(false)
            setNotificationContent('')
        }, 2000)

        return () => clearTimeout(timeoutId)
        }
    }, [showNotificationCompanie])


    useEffect(() => {

        if (showAlertCompanie) {
        const timeoutId = setTimeout(() => {
            setShowAlertCompanie(false)
            setAlertContent('')
        }, 2000)

        return () => clearTimeout(timeoutId)
        }
    }, [showAlertCompanie])

    function formatarDataHora(dataHora) {
        const date = new Date(dataHora)
      
        const dia = date.getDate()
        const mes = date.getMonth() + 1
        const ano = date.getFullYear()
      
        const hora = date.getHours()
        const minuto = date.getMinutes()
      
        return `${dia}/${mes}/${ano} ${hora}:${minuto}`
      }

    
    const handleItemClickGetCompanie = (itemAuthToken) => {
        setSelectedAuthTokenCompanieChats(itemAuthToken)
    }

    const handleSelectChangeChats = (selectedAuthToken) => {
        const selectedItem = returnGetCompanieChats.results.find(
          (item) => item.auth_token === selectedAuthToken
        )
        if (selectedItem) {
            handleItemClickGetCompanie(selectedItem.auth_token)
        }
    }
   
    const functionButtonSeeChats = async() => {

        if(!selectedAuthTokenCompanieChats){
            setAlertContent('🔔 Verificar: Seleção companie!')
            setShowAlertCompanie(true)
            //alert('Você não selecionou uma Companie, favor verificar!')
            return
        }
        //const idAsInteger = parseInt(selectedGetCompanie, 10)
        //console.log('DEV - Início GET CHATS // token a ser enviado no header:', selectedAuthTokenCompanieChats)
            try {
                const response = await axios.get(`${process.env.REACT_APP_REQ}chat`,{
                    headers: {
                        'companie-token': selectedAuthTokenCompanieChats
                    }
                })
                if (response.status === 200) {
                //console.log('response:', response)
                //console.log('data:', response.data)
                //console.log('status', response.status)
                setReturnGetChats(response.data)
            }
        }catch (error) {
            console.log(error)
            console.log(error.response.data.msg)
            if (error.response && error.response.status === 401) {
                navigate('/logout')
            }
        }finally {
            //console.log('DEV - FIM GET CHATS ')
        }
    }

      
    const handleClickChat = (chatId, serviceId, finishedInfo) => {
        setSelectedChatId(chatId)
        setSelectedServiceId(serviceId)
        setSelectedFinishedInfo(finishedInfo)
    }

    const resetModal = () => {
        if (selectedChatId !== null) {
          setSelectedChatId(null)
          setReturnGetChatsId(null)
          setDivStartChatShow(true)
        }
    }

    function formatHourDate(dateString) {
        const date = new Date(dateString)
      
        const dia = date.getDate()
        const mes = date.getMonth() + 1
        const ano = date.getFullYear()
      
        const hora = date.getHours()
        const minuto = date.getMinutes()
      
        const minuteStr = minuto < 10 ? `0${minuto}` : `${minuto}`
      
        return `${dia}/${mes}/${ano} ${hora}:${minuteStr}`
      }
    


    const handleShowModelTrainChats = () => {
        setDivShowModelTrainChats(!divShowModelTrainChats)
    }

    
    const handleCloseChat = async() => {

        let data = {
            id: selectedChatId,
            finished: true
        }
        
        //console.log('DEV - Início CLOSE CHAT PUT // data:', data)
            try {
                const response = await axios.put(`${process.env.REACT_APP_REQ}chat`, data,{
                    headers: {
                        'companie-token': selectedAuthTokenCompanieChats
                    }
                })
                if (response.status === 200) {
                setNotificationContent('🔔 Chat finalizado!')
                setShowNotificationCompanie(true)
                try {
                        const response = await axios.get(`${process.env.REACT_APP_REQ}chat`,{
                            headers: {
                                'companie-token': selectedAuthTokenCompanieChats
                            }
                        })
                        if (response.status === 200) {
                        setReturnGetChats(response.data)
                    }
                }catch (error) {
                    console.log(error)
                    console.log(error.response.data.msg)
                    if (error.response && error.response.status === 401) {
                        navigate('/logout')
                    }
                }finally {
                    //console.log('DEV - FIM GET CHATS ')
                }
                resetModal()
            }
        }catch (error) {
            console.log(error)
            console.log(error.response.data.msg)
        }finally {
            resetModal()
        }
    }


    return (
        <div className='div_chats'>
            <div className='div_chats_1'>
                <EnzoMenu />
                <EnzoMenuMobile />
            </div>
            <div className='div_chats_2'>
                <div className='div_chats_2_selection'>
                    <div className='div_chats_2_title'>C H A T S - <b>Y A N</b></div>
                    {returnGetCompanieChats && returnGetCompanieChats.results && returnGetCompanieChats.results.length > 0 ? (
                        <div className='div_chats_2_select_companie'>
                            <div className='div_chats_2_subtitle'><b>Selecione uma Companie:</b></div>
                            {/*returnGetCompanieChats.results.map((item) => (
                                <div className={item.auth_token === selectedAuthTokenCompanieChats ? 'div_chats_2_selected_item' : 'div_chats_2_selection_item'} onClick={() => handleItemClickGetCompanie(item.auth_token)}><b>{item.name.toUpperCase()}</b></div>
                            ))*/}
                            <select className='selectGeral' onChange={(e) => handleSelectChangeChats(e.target.value)}>
                                <option value="" selected={!selectedAuthTokenCompanieChats}>Selecione uma opção</option>
                                {returnGetCompanieChats.results.map((item) => (
                                    <option
                                    key={item.auth_token}
                                    value={item.auth_token}
                                    selected={item.auth_token === selectedAuthTokenCompanieChats}
                                    >
                                    {item.name.toUpperCase()}
                                    </option>
                                ))}
                            </select>
                            <button className='div_chats_2_butom'  onClick={functionButtonSeeChats}><b>VER CHATS</b></button>
                        </div>
                    ) : (
                        <div>
                            {divErrorGetCompanie && <div>
                                <DivError codeError={errorCompanieCode} textCodeError={errorCompanieCodeText} textError={errorCompanieText.toUpperCase()} imgAlt={'Imagem robo error'} imgSrc={errorImgOrange} imgClassName={'imgErrorWidth'}   />
                            </div>} 
                        </div>
                    )}
                </div>
                {showNotificationCompanie &&
                    <div className='div_Notification'>{notificationContent}</div>
                }
                {showAlertCompanie &&
                    <div className='div_Alert'>{alertContent}</div>
                }
                <div>
                    {returnGetChats &&
                        <div className='div_chats_2_titlePurple'><b>C H A T S </b></div>
                    }
                    {returnGetChats &&
                        <div className='div_chats_2_content_return'>
                            {returnGetChats && returnGetChats.chats.length > 0 ? (
                                returnGetChats.chats.map((chat) => (
                                    <div key={chat.id} onClick={() => handleClickChat(chat.id, chat.service_id, chat.finished)} className={chat.id === selectedChatId ? 'div_chats_2_selected_chat' : 'div_chats_2_select_chat'}>
                                        <div>💬 <b>{chat.id}</b></div>
                                        <div><b>{formatarDataHora(chat.inserted)}</b></div>
                                        {chat.finished.toString() === 'True' ? (
                                            <img src={iconFinalizado} className='iconGeral' alt={'Icone finalizado'}/>
                                        ) : (
                                            <img src={iconAndamento} className='iconGeral' alt={'Icone em andamento'}/>
                                        )}
                                        {chat.service_id === 'None' ? (
                                            <img src={iconUrl} className='iconGeral' alt={'Icone web'}/>
                                        ) : (
                                            <img src={iconWpp} className='iconGeral' alt={'Icone whatsapp'}/>
                                        )}
                                        {chat.service_id === 'None' ? (
                                            <div></div>
                                        ) : (
                                            <div>{chat.service_id}</div>
                                        )}
                                        
                                    </div>
                                ))
                            ) : (
                                <div className='div_chats_2_titlePurple'>Companie não possui chats</div>
                            )}
                        </div>
                    }
                    

                    <Modal show={selectedChatId !== null} onHide={resetModal}>
                            <Modal.Header closeButton>
                                <Modal.Title>CHATS</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                    <div className='chatModal'>
                                        {divStartChatShow && <div className='div_chats_2_titlePurple'>Carregando chats. . .</div>}
                                        {returnGetChatsId && selectedFinishedInfo.toString() !== 'True' &&
                                            <button className='div_talk_2_buttonCloseChat' onClick={handleCloseChat}><b>ENCERRAR CHAT</b></button>
                                        }
                                        {returnGetChatsId && returnGetChatsId.msgs.sort((a, b) => new Date(a.send_at) - new Date(b.send_at)).map((message, index) => {
                                            const className = message.send_by === 'ia_model' || message.send_by === 'system' ? 'div_talk_2_receivedMsg' : 'div_talk_2_sendMsg'
                                            return (
                                                <div key={index} className={className}>
                                                    
                                                    {message.model_train ? <div className='divModelTrainShow' ><b>Model Train</b></div> : null}
                                                    <div className='textjustify'>
                                                        <b>{message.send_by === 'ia_model' || message.send_by === 'system' ? 'Y A N:' : 'Você:'}</b>
                                                    </div>
                                                    {message.model_train ?
                                                        <div className={'divModelTrainButtonForOpen'}>
                                                            <button className={'divModelTrainShowButton'} onClick={handleShowModelTrainChats}>{divShowModelTrainChats ? 'OCULTAR' : 'EXPANDIR'}</button>
                                                            {divShowModelTrainChats && <div>{message.message}</div>}
                                                        </div>
                                                        :
                                                        <div>{message.message}</div>
                                                    }
                                                    {message.model_train ? null : <div>{formatHourDate(message.send_at)}</div>}
                                                    
                                                </div>
                                                
                                            )
                                        })}
                                    </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={resetModal}>
                                    Fechar
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    <div className='div_talk_2_logo'> {/*unico q se mantem talk*/}
                        <div><Logo width={'200px'} imageUrl={logoPreta} alt={'Logo Young cor Preto'} /></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EnzoChats