import React, { useState } from 'react'
import './menu.css'
import ButtonPadrao from '../ButtonPadrao'
import { Link } from 'react-router-dom'
import PhraseInsert from '../PhraseInsert'

function EnzoMenuOpen() {

  return (
    <div className='div_menu_general'>
        <div className='div_menu_general_buttons'>
            <Link to="/home"><ButtonPadrao backgroundColor={'#FF5C33'} text={'Home'}/></Link>
            <Link to="/companie"><ButtonPadrao backgroundColor={'#FF5C33'} text={'Companie'}/></Link>
            <Link to="/talk"><ButtonPadrao backgroundColor={'#FF5C33'} text={'Fale com a Send'}/></Link>
            <Link to="/showchats"><ButtonPadrao backgroundColor={'#FF5C33'} text={'Chats'}/></Link>
            <Link to="/logout"><ButtonPadrao backgroundColor={'#737d81'} text={'Logout'}/></Link>
        </div>
    </div>
  )
}

export default EnzoMenuOpen
