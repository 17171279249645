// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.conversion_cvs_bg__aT7cu{
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
}

.conversion_cvs_content__VYrPz{
    position: relative;
    max-width: 1200px;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 2rem;
    align-items: center;
    justify-content: center;
    padding: 3% 0;
}

.conversion_cvs_focus__Aq89k{
    position: relative;
    width: 50%;
    display: flex;
    flex-direction: column;
    gap:1rem;
    text-align: left;
    align-items:flex-start;
}

.conversion_cvs_focus__Aq89k b{
    color: #FF5C33;
}

.conversion_cvs_focus__Aq89k img{
    width: 230px;
    height: auto;

}

.conversion_cvsimg__YTzwJ {
    position: relative;
    transition: transform 0.3s ease; /* Adiciona uma transição suave ao efeito */
}

.conversion_cvsimg__YTzwJ:hover{
    transform: scale(0.95); /* Aumenta o tamanho do conteúdo em 10% */
}



@media(max-width: 1000px){
    .conversion_cvs_bg__aT7cu{
        padding: 5% 10%;
    }
    .conversion_cvs_content__VYrPz{
        flex-direction: column-reverse;
        gap:1rem;
    }
        
    .conversion_cvs_focus__Aq89k{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap:1rem;
        text-align: left;
        align-items: center;
    }


}`, "",{"version":3,"sources":["webpack://./src/components/Conversion/conversion.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,UAAU;AACd;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,aAAa;IACb,sBAAsB;IACtB,QAAQ;IACR,gBAAgB;IAChB,sBAAsB;AAC1B;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,YAAY;IACZ,YAAY;;AAEhB;;AAEA;IACI,kBAAkB;IAClB,+BAA+B,EAAE,2CAA2C;AAChF;;AAEA;IACI,sBAAsB,EAAE,yCAAyC;AACrE;;;;AAIA;IACI;QACI,eAAe;IACnB;IACA;QACI,8BAA8B;QAC9B,QAAQ;IACZ;;IAEA;QACI,WAAW;QACX,aAAa;QACb,sBAAsB;QACtB,QAAQ;QACR,gBAAgB;QAChB,mBAAmB;IACvB;;;AAGJ","sourcesContent":[".cvs_bg{\n    position: relative;\n    width: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    padding: 0;\n}\n\n.cvs_content{\n    position: relative;\n    max-width: 1200px;\n    width: 100%;\n    display: flex;\n    flex-direction: row;\n    gap: 2rem;\n    align-items: center;\n    justify-content: center;\n    padding: 3% 0;\n}\n\n.cvs_focus{\n    position: relative;\n    width: 50%;\n    display: flex;\n    flex-direction: column;\n    gap:1rem;\n    text-align: left;\n    align-items:flex-start;\n}\n\n.cvs_focus b{\n    color: #FF5C33;\n}\n\n.cvs_focus img{\n    width: 230px;\n    height: auto;\n\n}\n\n.cvsimg {\n    position: relative;\n    transition: transform 0.3s ease; /* Adiciona uma transição suave ao efeito */\n}\n\n.cvsimg:hover{\n    transform: scale(0.95); /* Aumenta o tamanho do conteúdo em 10% */\n}\n\n\n\n@media(max-width: 1000px){\n    .cvs_bg{\n        padding: 5% 10%;\n    }\n    .cvs_content{\n        flex-direction: column-reverse;\n        gap:1rem;\n    }\n        \n    .cvs_focus{\n        width: 100%;\n        display: flex;\n        flex-direction: column;\n        gap:1rem;\n        text-align: left;\n        align-items: center;\n    }\n\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cvs_bg": `conversion_cvs_bg__aT7cu`,
	"cvs_content": `conversion_cvs_content__VYrPz`,
	"cvs_focus": `conversion_cvs_focus__Aq89k`,
	"cvsimg": `conversion_cvsimg__YTzwJ`
};
export default ___CSS_LOADER_EXPORT___;
