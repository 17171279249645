import React from 'react';
import styles from './ia.module.css';

const IaComp = () => {
  return (
    <div className={styles.ia_bg}>
        <div className={styles.ia_content}>
            <div className={styles.ia_ct1}>
                <h2>Envolva Seus Clientes com Abordagem Programada e Ofertas Personalizadas</h2>
            </div>
            <div className={styles.ia_ct2}>
                <p className='textglobal'><b>Transforme suas campanhas</b> com <b>inteligência artificial</b> que combina <b>abordagem programada e envio massivo de ofertas</b>. Programe interações automáticas e relevantes para cada cliente, aumentando conversões e fortalecendo relacionamentos ao <b>engajar seus clientes no momento certo.</b></p>
            </div>
        </div>
    </div>
  );
};

export default IaComp;
