import React, { forwardRef } from 'react'
import styles from './contato.module.css'
import Form from '../Form'

const Contato = forwardRef((props, ref) => {
  return (
    <div ref={ref} className={styles.ctt}>
        <div className={styles.cttcontent}>
            <div className={styles.left}>
              <div className={styles.col}>
                <Form />
              </div>
            </div>
            <div className={styles.right}>
              <p className={styles.rightstrong}>CONTATO</p>
              <p>Informe seus dados para que a Send te acione via Whatsapp!</p>
            </div>
        </div>
    </div>
  )
})

export default Contato