// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form_form__v9NsD{
    display: flex;
    flex-direction: column;
    gap:16px;
    align-items: center;
}

.form_labels__l94be{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 400px;
}

.form_labels_ctt__Qdz8O{
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #8933FF;
    height: 48px;
    border-radius: 8px 0 0 8px;
}

@media(max-width:1000px){
    .form_labels__l94be{
        width: 300px;
        max-width: 300px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Form/form.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,QAAQ;IACR,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,uBAAuB;IACvB,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,yBAAyB;IACzB,YAAY;IACZ,0BAA0B;AAC9B;;AAEA;IACI;QACI,YAAY;QACZ,gBAAgB;IACpB;AACJ","sourcesContent":[".form{\n    display: flex;\n    flex-direction: column;\n    gap:16px;\n    align-items: center;\n}\n\n.labels{\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: center;\n    width: 400px;\n}\n\n.labels_ctt{\n    width: 60px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    background-color: #8933FF;\n    height: 48px;\n    border-radius: 8px 0 0 8px;\n}\n\n@media(max-width:1000px){\n    .labels{\n        width: 300px;\n        max-width: 300px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `form_form__v9NsD`,
	"labels": `form_labels__l94be`,
	"labels_ctt": `form_labels_ctt__Qdz8O`
};
export default ___CSS_LOADER_EXPORT___;
