import React from 'react'
import styles from './second.module.css'

const SecondComponent = ({ scrollToRef, contatoRef }) => {
  return (
    <div className={styles.div_bg}>
        <div className={styles.div_content}>
            <div className={styles.div_text}>
              <h2>Impulsione o Retorno de Clientes com a <span className='color1'>Send</span> e Acionamento Programado!</h2>
              <p className='textglobal'><b>Transforme Seu Atendimento com a <span className='color1'>Send</span>, nossa IA de Linguagem Natural e Acionamento Recorrente!</b><br/> A Send otimiza o tempo e engaja seus clientes pelo WhatsApp, convidando-os a retornar e consumir seus produtos ou serviços. Com nossa IA, você fideliza clientes, agenda compromissos, envia documentos e incentivos personalizados, tudo de forma eficiente e automatizada de acordo com seu treinamento.</p>
              <div className={styles.rowItem}>
                <button className={'button'} onClick={() => scrollToRef(contatoRef)}>IMPULSIONAR AGORA</button>
                <button className={'button2'}>FALE COM A SEND</button>
              </div>
            </div>
            <div>
              <picture>
                <source srcSet="/new/imgheadmb.png" media="(max-width:800px)" sizes="(max-width: 800px) 100vw, 50vw" />
                <img src="/new/img1head.jpg" alt="Imagem div" className={styles.img1} />
              </picture>
                
            </div>
        </div>
    </div>
  )
}
export default SecondComponent
