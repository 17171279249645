import React from 'react'
import styles from './footer.module.css'

const Footer = () => {
  return (
    <footer className={styles.footer}>
        <div className={styles.footerctt}>
            <div>
                <img src="/new/logo/logo.png" alt="Logo Send IA" width="100" height="100" className={styles.imglogo}/>
            </div>
            {/* Informações de contato */}
            <div >
                <p>
                <a href="mailto:contato@send.ia">contato@send.ia</a><br />
                <a href="tel:+5511999999999">(11) 9 9999 9999</a>
                </p>
            </div>

            {/* Direitos autorais */}
            <div >
                <p>©️ 2024 Send IA. Todos os direitos reservados.</p>
                <p>ASCOM MONTAGENS - CNPJ 05.816.317/0001-14</p>
            </div>

            {/* Endereço */}
            <address >
                Avenida Washington Luis, 4000 - COMPLEMENTO BLOCO C (APT 13) <br />
                CEP 13.042-105 BAIRRO/DISTRITO VILA MARIETA <br />
                MUNICÍPIO CAMPINAS
            </address>

            {/* Ícones de redes sociais (imagem 30px x 30px) */}
            <div >
                <img src="/new/icons/whatsapp.png" alt="Ícone Rede Social 1" width="30" height="30"  />
                <img src="/new/icons/insta.png" alt="Ícone Rede Social 2" width="30" height="30"  />
            </div>

        </div>
    </footer>
  )
}

export default Footer
