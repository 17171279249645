import './StartPage.css'
import React, { useRef } from 'react'
import FirstContent from '../../components/FirstContent'
import SecondComponent from '../../components/Second'
import HeaderComp from '../../components/HeaderComp'
import Conversion from '../../components/Conversion'
import Revolution from '../../components/Revolution'
import Engagement from '../../components/Engagement'
import Skills from '../../components/Skills'
import IaComp from '../../components/IA'
import Contato from '../../components/Contato'
import Footer from '../../components/Footer'

const StartPage = () => {

    const contatoRef = useRef(null)

    const scrollToRef = (ref) => {
        if (ref?.current) {
            const elementPosition = ref.current.getBoundingClientRect().top + window.scrollY;
            const offsetPosition = elementPosition - 100; // Subtrai 100px para subir um pouco

            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth',
            })
        }
    }

    return (
        <main>
            <FirstContent 
                scrollToRef={scrollToRef}  // Passando scrollToRef como prop
                contatoRef={contatoRef}    // Passando contatoRef como prop
            />
            <HeaderComp
                scrollToRef={scrollToRef}  // Passando scrollToRef como prop
                contatoRef={contatoRef}    // Passando contatoRef como prop
            />
            <SecondComponent 
                scrollToRef={scrollToRef}  // Passando scrollToRef como prop
                contatoRef={contatoRef}    // Passando contatoRef como prop
            />
            <Conversion  
                scrollToRef={scrollToRef}  // Passando scrollToRef como prop
                contatoRef={contatoRef}    // Passando contatoRef como prop
            />
            <Revolution  
                scrollToRef={scrollToRef}  // Passando scrollToRef como prop
                contatoRef={contatoRef}    // Passando contatoRef como prop
            />
            <Engagement  
                scrollToRef={scrollToRef}  // Passando scrollToRef como prop
                contatoRef={contatoRef}    // Passando contatoRef como prop
            />
            <Skills  
                scrollToRef={scrollToRef}  // Passando scrollToRef como prop
                contatoRef={contatoRef}    // Passando contatoRef como prop
            />
            <IaComp />
            <div ref={contatoRef}>
                <Contato />
            </div>
            <Footer />
        </main>
    )
}

export default StartPage
