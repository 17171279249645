// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.footer_footer__R1A6a{
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    background-color: #0e0e0e;
}

.footer_footerctt__VpzQn{
    position: relative;
    max-width: 1200px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    justify-content: center;
    padding: 2% 0;
    color: #FF6F40;
}
.footer_footerctt__VpzQn a{
    text-decoration: none;
    color: #FF6F40;
}
.footer_imglogo__oXACr{
    object-fit: contain;
}

.footer_footerctt__VpzQn{
    font-size: 14px;
}

.footer_srOnly__fcPKn {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}
@media(max-width:1000px){
    .footer_footerctt__VpzQn{
        padding: 5% 10%;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Footer/footer.module.css"],"names":[],"mappings":";AACA;IACI,kBAAkB;IAClB,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,UAAU;IACV,yBAAyB;AAC7B;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,WAAW;IACX,uBAAuB;IACvB,aAAa;IACb,cAAc;AAClB;AACA;IACI,qBAAqB;IACrB,cAAc;AAClB;AACA;IACI,mBAAmB;AACvB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,WAAW;IACX,UAAU;IACV,YAAY;IACZ,gBAAgB;IAChB,sBAAsB;IACtB,SAAS;AACb;AACA;IACI;QACI,eAAe;IACnB;AACJ","sourcesContent":["\n.footer{\n    position: relative;\n    width: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    padding: 0;\n    background-color: #0e0e0e;\n}\n\n.footerctt{\n    position: relative;\n    max-width: 1200px;\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    gap: 0.5rem;\n    justify-content: center;\n    padding: 2% 0;\n    color: #FF6F40;\n}\n.footerctt a{\n    text-decoration: none;\n    color: #FF6F40;\n}\n.imglogo{\n    object-fit: contain;\n}\n\n.footerctt{\n    font-size: 14px;\n}\n\n.srOnly {\n    position: absolute;\n    width: 1px;\n    height: 1px;\n    padding: 0;\n    margin: -1px;\n    overflow: hidden;\n    clip: rect(0, 0, 0, 0);\n    border: 0;\n}\n@media(max-width:1000px){\n    .footerctt{\n        padding: 5% 10%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `footer_footer__R1A6a`,
	"footerctt": `footer_footerctt__VpzQn`,
	"imglogo": `footer_imglogo__oXACr`,
	"srOnly": `footer_srOnly__fcPKn`
};
export default ___CSS_LOADER_EXPORT___;
