import React, { useState } from 'react';
import styles from './faq.module.css';

const Faq = () => {
    const [openQuestions, setOpenQuestions] = useState({ 0: true });

    const toggleQuestion = (index) => {
        setOpenQuestions((prevState) => ({
        ...prevState,
        [index]: !prevState[index],
        }));
    };

    const faqData = [
    {
        question: 'O que é uma mensagem programada/recorrente?',
        answer: ` A mensagem programada permite que você defina um intervalo de tempo específico para que a Send, nossa assistente virtual, envie automaticamente mensagens via WhatsApp para seus clientes. Isso garante um contato consistente e eficiente, sem a necessidade de acompanhamento manual. Com essa função, você economiza tempo, mantém o relacionamento com seus clientes ativo e personalizado, e ainda aumenta suas chances de conversão e fidelização. Além disso, a cada mensagem, você pode oferecer promoções exclusivas, como cashbacks e descontos, incentivando seus clientes a retornarem e aproveitarem mais serviços — tudo de forma automática e no momento certo. Dessa forma, você não apenas mantém contato regular, mas também cria oportunidades para aumentar as vendas e recompensar a lealdade dos seus clientes. `,
    },
    {
        question: 'O que a Send faz no atendimento 24 horas por dia?',
        answer: ` A Send está disponível 24 horas por dia para agendar serviços, responder dúvidas sobre disponibilidade de horários, preços e tipos de atendimento, como corte de cabelo, serviços veterinários, manutenção de veículos ou tratamentos odontológicos. Além disso, ela pode enviar informações importantes como orçamentos, listas de serviços, e até fotos ou vídeos de antes e depois, tudo de forma automática. Com a Send, seu negócio está sempre acessível, garantindo que seus clientes possam fazer agendamentos ou tirar dúvidas a qualquer hora, mesmo fora do horário comercial. Isso significa mais praticidade, agilidade e fidelização, mantendo seus clientes sempre bem atendidos e conectados ao seu serviço, sem que você precise se preocupar. `,
    },
    {
        question: 'O que faz exatamente o processo de pesquisa de satisfação?',
        answer: `Após cada atendimento ou serviço realizado, a Send envia automaticamente uma pesquisa de satisfação via WhatsApp, pedindo para seu cliente avaliar a experiência. Com isso, você recebe feedback em tempo real, identificando rapidamente pontos de melhoria e reconhecendo os clientes que estão satisfeitos e mais propensos a voltar. Esse processo ajuda seu negócio a melhorar continuamente, oferecendo um serviço cada vez mais alinhado às expectativas dos clientes, além de fortalecer o relacionamento com aqueles que já estão satisfeitos, incentivando a fidelização e indicações. `,
    },
    {
        question: 'E como é o processo de contratação?',
        answer: `É bem simples! Você pode conectar a Send ao número de WhatsApp que seus clientes já conhecem, ou, se preferir, podemos fornecer um novo número personalizado com o nome do seu negócio. Após a configuração, você receberá o login e a senha para acessar nossa plataforma, onde poderá acompanhar todas as conversas em tempo real, além de ter uma visão completa de todas as transações, agendamentos e mensagens enviadas pela Send.Com isso, você tem controle total do atendimento, além de manter seus clientes sempre conectados e bem atendidos, sem complicações.`,
    },
    ];

    return (
        <div className={styles.faq_bg}>
          <div className={styles.faq_content}>
            <div className={styles.left}>
              FAQ
            </div>
            <div className={styles.right}>
              {faqData.map((item, index) => (
                <div key={index} className={styles.rgtct}>
                  <div onClick={() => toggleQuestion(index)} className={styles.question}>
                    {/* Imagem condicional com base no estado (aberta ou fechada) */}
                    <img
                      src="/new/icons/arrow.svg"
                      alt="Ícone pessoa"
                      className={openQuestions[index] ? styles.iconimgopen : styles.iconimg}
                    />
                    <p className={openQuestions[index] ? styles.textselected : styles.textbase}>{item.question}</p>
                  </div>
                  {openQuestions[index] && (
                    <div className={styles.answer}>
                      <p>{item.answer}</p>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      );
    };

    export default Faq;
