import './EnzoRegister.css'
import React from 'react'
import { useState, useEffect } from 'react'
import logoPreta from '../img/send.png'
import ButtonPadrao from '../../components/ButtonPadrao'
import TextAlert from '../../components/TextAlert'
import Input from '../../components/Input'
import Logo from '../../components/Logo'
import PhraseInsert from '../../components/PhraseInsert'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import loadingRosa from '../img/send.png'
import LoadingImageRotate from '../../components/LoadingImageRotate'

const EnzoRegister = () => {
    const [emailRegister, setEmailRegister] = useState('')
    const [pswRegister, setPswRegister] = useState('')
    const [pswConfirmRegister, setPswConfirmRegister] = useState('')
    const [nameRegister, setNameRegister] = useState('')
    const [passwordMatch, setPasswordMatch] = useState(true)
    const [loadingRegister, setLoadingRegister] = useState(false)
    const navigate = useNavigate()

    const [showNotificationCompanie, setShowNotificationCompanie] = useState(false)
    const [showAlertCompanie, setShowAlertCompanie] = useState(false)

	const [notificationContent, setNotificationContent] = useState(null)
	const [alertContent, setAlertContent] = useState(null)
    

    const ConfirmEqualPsw =  (e) => {
        setPswConfirmRegister(e.target.value)

        if(pswRegister === e.target.value){
            setPasswordMatch(true)
        }else{
            setPasswordMatch(false)
        }

    }

    
    useEffect(() => {

        if (showNotificationCompanie) {
        const timeoutId = setTimeout(() => {
            setShowNotificationCompanie(false)
            setNotificationContent('')
        }, 2000)

        return () => clearTimeout(timeoutId)
        }
    }, [showNotificationCompanie])


    useEffect(() => {

        if (showAlertCompanie) {
        const timeoutId = setTimeout(() => {
            setShowAlertCompanie(false)
            setAlertContent('')
        }, 2000)

        return () => clearTimeout(timeoutId)
        }
    }, [showAlertCompanie])
	
	
    const buttonRegisterEnd = async () => {
        if(!emailRegister){
            setAlertContent('🔔 Verificar: E-mail!')
            setShowAlertCompanie(true)
            //alert('Favor inserir e-mail')
            return
        }
        if(pswRegister !== pswConfirmRegister){
            setAlertContent('🔔 Verificar: Senhas não coincidem!')
            setShowAlertCompanie(true)
            //alert('Senhas não coincidem!')
            return
        }
        if(!pswRegister){
            setAlertContent('🔔 Verificar: Senha!')
            setShowAlertCompanie(true)
            //alert('Você não inseriu sua senha!')
            return
        }
        if(!pswConfirmRegister){
            setAlertContent('🔔 Verificar: Confirmação de senha!')
            setShowAlertCompanie(true)
            //alert('Você não confirmou sua senha!')
            return
        }
        if(!nameRegister){
            setAlertContent('🔔 Verificar: Nome registro!')
            setShowAlertCompanie(true)
            //alert('Você não informou um nome para registro!')
            return
        }
        try {
            setLoadingRegister(true)
            //console.log('email para requisição:', emailRegister)
            //console.log('nome para requisição:', nameRegister)
            //console.log('password para requisição:', pswRegister)
            //console.log('Começando a requisição de cadastro')
            const response = await axios.post(`${process.env.REACT_APP_REQ}user`, {
                "email":emailRegister,
                "name":nameRegister,
                "password":pswRegister,
            })
            if (response.status === 200) {
                //console.log('Response 200 - data:', response.data)
                alert(response.data.msg)
                //alert('Cadastrado com sucesso! Você recebera um e-mail com um link para confirmar seu cadastro na plataforma, assim que confirmado você poderá acessá-la!')
                navigate('/login')
            }
        }catch (error) {
            console.log(error)
            await setAlertContent('🔔 ', error.response.data.msg)
            await setShowAlertCompanie(true)
            //alert(error.response.data.msg)
        }finally {
            //console.log('REQUISIÇÃO CADASTRO - FIM')
            setLoadingRegister(false)
        }
    }

    
    return (
        <div className='div_register'>
            <div className='div_register_0'>
                <div className='div_register_1'>
                    <PhraseInsert phraseInsert={'Inicie sua jornada registrando-se na plataforma e, após o login, personalize a Send de acordo com suas necessidades!'} />
                </div>
                <div className='div_register_2'>
                    <div className='div_register_title'>SEND - <b>CADASTRO DE PERFIL E PROPRIEDADES</b></div>
                    <div className='div_register_label'><b>Informe o e-mail de cadastro:</b></div>
                    <Input type={'text'} placeholder={'Informe o e-mail'} borderRange={'2px'} value={emailRegister} onChange={(e) => setEmailRegister(e.target.value)} borderColor={'#222323'} />
                    <div className='div_register_label'><b>Cadastre sua senha:</b></div> 
                    <Input type={'password'} placeholder={'Informe a senha'} borderRange={'2px'} value={pswRegister} onChange={(e) => setPswRegister(e.target.value)} borderColor={'#222323'} />
                    <Input type={'password'} placeholder={'Confirme a senha'} borderRange={'2px'} value={pswConfirmRegister} onChange={ConfirmEqualPsw} borderColor={'#222323'} />
                    {!passwordMatch && <div className='div_register_alert_text'><TextAlert text={'ATENÇÃO! As senhas informadas não conferem :('}/></div>}
                    <div className='div_register_label'><b>Cadastre um nome:</b></div>
                    <Input type={'text'} placeholder={'Informe um nome'} borderRange={'2px'} value={nameRegister} onChange={(e) => setNameRegister(e.target.value)} borderColor={'#222323'} />
                    
                    <div className='div_register_button_logo'>
                        <div className='div_register_confirm'><ButtonPadrao backgroundColor={'#FF5C33'} onClick={buttonRegisterEnd} disabled={loadingRegister} text={'CADASTRAR'}/></div>
                        {loadingRegister &&<LoadingImageRotate loadingImage={loadingRosa}  altLoadingImage={'Loading image'} width={'60px'} text={'S e n d...'} />}
                        <div><Logo width={'200px'} imageUrl={logoPreta} alt={'Logo cor Preto'} /></div>
                    </div>
                </div>
                {showNotificationCompanie &&
                    <div className='div_Notification'>{notificationContent}</div>
                }
                {showAlertCompanie &&
                    <div className='div_Alert'>{alertContent}</div>
                }
            </div>
        </div>
    )
}

export default EnzoRegister